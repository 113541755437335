import { IsPrinterConnected } from '../utils/terminal';
import { toWithReturnParam } from '../utils/navigationHelper';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useProcessPayment, useSinglePaymentType } from '../modules/payment/hooks';
import { useState } from 'react';
import { useAppSelector } from '../utils/hooks';
import { getAppDetails } from '../modules/layout/selectors';

interface ConnectingState {
  status: ConnectingStatus;
  error: string | null;
}

const useValidateSettingsAndPay = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [singlePaymentType] = useSinglePaymentType();
  const appDetails = useAppSelector((l) => getAppDetails(l.layout));
  const [processPayment] = useProcessPayment(singlePaymentType);
  const [printerConnectingState, setPrinterConnectingState] = useState({
    status: ConnectingStatus.Idle,
    error: null,
  } as ConnectingState);

  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const validateSettingsAndPay = async () => {
    if (window.BtDevice) {
      if (appDetails.hotspotRequired) {
        console.log('[validateSettingsAndPay] Hotspot is required');
        const isHotspotEnabled = window.BtDevice.isHotspotEnabled();
        if (!isHotspotEnabled) {
          console.log('[validateSettingsAndPay] Hotspot is NOT enabled');
          window.BtDevice.openHotspotSettings();

          return;
        }
        console.log('[validateSettingsAndPay] Hotspot is enabled');
      } else {
        console.log('[validateSettingsAndPay] Hotspot is NOT required');
      }
      if (appDetails.fiscalPrinterRequired) {
        console.log('[validateSettingsAndPay] fiscalPrinterRequired - pending');
        setPrinterConnectingState({ status: ConnectingStatus.Pending, error: null });
        await timeout(1);
        const isPrinterConnected = await IsPrinterConnected();
        console.log('[validateSettingsAndPay] isPrinterConnected', isPrinterConnected);
        if (!isPrinterConnected) {
          setPrinterConnectingState({ status: ConnectingStatus.Error, error: 'Printer is not connected.' });
          return;
        }
        setPrinterConnectingState({ status: ConnectingStatus.Connected, error: null });
      }
    }

    console.log('validateSettingsAndPay - singlePaymentType: ', singlePaymentType);
    const ticketUuid = searchParams.get('ticketId');
    if (singlePaymentType) {
      await processPayment(ticketUuid);
    } else {
      let paymentWizardUrl = '/payment_wizard';
      if (ticketUuid) {
        paymentWizardUrl += `?ticketId=${ticketUuid}`;
      }
      navigate(toWithReturnParam(location, paymentWizardUrl));
    }
  };

  const resetState = (): void => {
    setPrinterConnectingState({ status: ConnectingStatus.Idle, error: null });
  };

  return [validateSettingsAndPay, printerConnectingState.status, printerConnectingState.error, resetState] as const;
};

export enum ConnectingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Connected = 'connected',
  Error = 'rejected',
}

export default useValidateSettingsAndPay;
