import { Box, Button, ButtonProps, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';

interface ItemProps extends ButtonProps {
  iconUrl: string;
  description: string;
  pageUrl: string;
}

const Item = (props: ItemProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { sx, description, iconUrl, pageUrl, ...other } = props;
  const getFontSize = (description: string): string => {
    if (matchesDownSM) {
      return description.length > 20 ? '1.2rem' : '1.7rem';
    }
    if (matchesDownMd) {
      return description.length > 20 ? '1.2rem' : '1.7rem';
    }
    return description.length > 20 ? '2rem' : '2.85rem';
  };
  return (
    <Button
      sx={{
        maxHeight: '30vmin',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        borderRadius: '4px',
        ...sx,
      }}
      {...other}
      onClick={() => navigate(pageUrl)}
    >
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={4} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <Box
            component="img"
            src={iconUrl}
            sx={{ maxWidth: matchesDownSM ? 65 : 105, maxHeight: matchesDownSM ? 44 : 71 }}
          ></Box>
        </Grid>
        <Grid
          item
          xs={7}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            borderLeft: '2px dashed #FFFFFF',
            height: '100%',
            px: 2,
          }}
        >
          <Typography fontSize={getFontSize(description)} color="white">
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

export default Item;
