import { InvoiceData } from '../tickets/models';
import { Product } from '../products/models';
import { TerminalState } from './const';

export enum PaymentType {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  FREE_TICKET = 'FREE_TICKET',
}
export interface RequestTicket {
  variantId: number;
  quantity: number;
  question: RequestTicketQuestion;
}

export interface ProductOrder {
  variantId: string;
  quantity: number;
  productId: string;
}

export interface RequestTicketQuestion {
  answer: string;
  questionId: number;
}

export interface CreateOrderRequest {
  tickets: RequestTicket[];
  invoiceData: InvoiceData;
  products: ProductOrder[];
}
export interface OrderTicket {
  uuid: string;
  qrCode: string;
  name: string;
  variantName: string;
  price: number;
  vat: number;
}
export interface OrderProduct {
  id: string;
  name: string;
  variantId: string;
  variantName: string;
  price: number;
  vat: number;
}
export interface OrderStatus {
  orderId: string;
  totalAmount: number;
  tickets: OrderTicket[];
  products: OrderProduct[];
}
export interface ConfirmPaymentRequest {
  amount: number;
  orderId: string;
  paymentType: PaymentType;
  transactionId: string | null;
}

export interface PaymentStateModel {
  orderStatus: OrderStatus | null;
}

export interface PaymentProcessingStatus {
  terminalState?: TerminalState;
  ip?: string;
  port: string;
}
