import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../types';
import { ParkingTicketEntry, ParkingTicketsStateModel } from './models';
import { fetchParkingTickets } from './operations';

const initialState = {
  parkingTickets: [] as ParkingTicketEntry[],
  isLoading: false,
} as ParkingTicketsStateModel;

const parkingTicketsSlice = createSlice({
  name: Slice.Tickets,
  initialState: initialState,
  reducers: {
    /*setLoadingStatus: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },*/
  },
  extraReducers(builder) {
    builder.addCase(fetchParkingTickets.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchParkingTickets.fulfilled, (state, action: PayloadAction<ParkingTicketEntry[]>) => {
      state.parkingTickets = action.payload;
      state.isLoading = false;
    });
  },
});

export const {} = parkingTicketsSlice.actions;
export default parkingTicketsSlice.reducer;
