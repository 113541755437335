import { Grid } from '@mui/material';
import PriceTicketTile from '../../components/TicketButton/Tiles/PriceTicketTile';
import { getNormalTickets, getParkingTickets, getReducedTickets, getTickets } from '../../modules/tickets/selectors';
import { useAppSelector } from '../../utils/hooks';
import { useMemo } from 'react';
import ParkingTicketTile from '../../components/TicketButton/Tiles/ParkingTicketTile';
const MainTickets = () => {
  const reducedTickets = useAppSelector((state) => getReducedTickets(state.tickets));
  const normalTickets = useAppSelector((state) => getNormalTickets(state.tickets));
  const parkingTickets = useAppSelector((state) => getParkingTickets(state.tickets));
  const tickets = useAppSelector((state) => getTickets(state.tickets));

  const mdColumnsCount = useMemo(() => {
    if (tickets.length === 1) {
      return 12;
    }
    return 6;
  }, [tickets]);

  return (
    <>
      <Grid container spacing={4}>
        {parkingTickets !== undefined && parkingTickets.length > 0 && (
          <>
            {parkingTickets?.map((parkingTicket) => (
              <Grid item xs={12} md={mdColumnsCount} key={parkingTicket.variantId}>
                <ParkingTicketTile ticket={parkingTicket}></ParkingTicketTile>
              </Grid>
            ))}
          </>
        )}
        {normalTickets !== undefined && normalTickets.length > 0 && (
          <>
            {normalTickets?.map((normalTicket) => (
              <Grid item xs={12} md={mdColumnsCount} key={normalTicket.variantId}>
                <PriceTicketTile ticket={normalTicket}></PriceTicketTile>
              </Grid>
            ))}
          </>
        )}
        {reducedTickets !== undefined && reducedTickets.length > 0 && (
          <>
            {reducedTickets?.map((reducedTicket) => (
              <Grid item xs={12} md={mdColumnsCount} key={reducedTicket.variantId}>
                <PriceTicketTile ticket={reducedTicket}></PriceTicketTile>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default MainTickets;
