import {
  Grid,
  Typography,
  List,
  ListItemButton,
  ListItemAvatar,
  Box,
  ListItemText,
  Snackbar,
  Alert,
  AlertColor,
  CircularProgress,
  Stack,
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { PaymentType } from '../modules/payment/models';
import { useAppSelector } from '../utils/hooks';
import { getBasketSummary } from '../modules/basket/selectors';
import WizardSteps from './paymentWizzard/WizardSteps';
import PaymentWrapper from '../components/PaymentWrapper';
import { getClientLayout } from '../modules/layout/selectors';
import { Layout } from '../setup/Layout';
import { RedButton } from '../components/styled-components';
import { useProcessPayment, useSinglePaymentType } from '../modules/payment/hooks';
import { useTranslation } from 'react-i18next';

const PaymentAmountText = styled(Typography)({ fontSize: '2rem', fontFamily: 'Poppins', color: '#2B2A2A' });
const PaymentTypeText = styled(Typography)({
  fontSize: '1.5rem',
  fontFamily: 'Poppins',
  fontWeight: 700,
  color: '#2B2A2A',
});

const PaymentWizard = () => {
  const { t } = useTranslation('common');
  const theme = useTheme();
  const isDownSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = React.useState<boolean>(false);
  const [type, setType] = React.useState<AlertColor>('success');
  const [message, setMessage] = React.useState<string>('');
  const [selectedPaymentType, setSelectedPaymentType] = React.useState<PaymentType | null>(null);
  const [processPayment, processing, paymentError] = useProcessPayment(selectedPaymentType);

  const basketSummary = useAppSelector((state) => getBasketSummary(state.basket));

  const clientLayout = useAppSelector((state) => getClientLayout(state.layout));
  const [singlePaymentType, displayCashPayment, displayCreditCardPayment] = useSinglePaymentType();

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handlePaymentButtonClick = async () => {
    await processPayment();
  };

  const handlePaymentTypeClick = (pt: PaymentType) => {
    if (pt === selectedPaymentType) {
      setSelectedPaymentType(null);
    } else {
      setSelectedPaymentType(pt);
    }
  };

  useEffect(() => {
    if (singlePaymentType != null) {
      setSelectedPaymentType(singlePaymentType);
    }
  }, [singlePaymentType]);

  useEffect(() => {
    if (paymentError.message?.length > 0) {
      setMessage(paymentError.message);
      setType(paymentError.severity);
      setOpen(true);
    }
  }, [paymentError]);

  const PaymentTypeList = () => (
    <List sx={{ mx: isDownSmallScreen ? 1 : 3, display: 'block' }}>
      {displayCreditCardPayment && (
        <ListItemButton
          sx={{
            backgroundColor: selectedPaymentType === PaymentType.CREDIT_CARD ? '#9464FC' : '#F4F0FF',
            mb: 3,
            pl: 4,
            height: '134px',
            borderWidth: 4,
            borderStyle: 'solid',
            borderColor: selectedPaymentType === PaymentType.CREDIT_CARD ? '#FFFFFF' : '#FFFFFF',
            color: selectedPaymentType === PaymentType.CREDIT_CARD ? '#FFFFFF' : 'inherit',
            borderRadius: '5px',
          }}
          onClick={() => handlePaymentTypeClick(PaymentType.CREDIT_CARD)}
        >
          <ListItemAvatar sx={{ mr: 3 }}>
            <Box component="img" src="/images/card_payment.png" sx={{ maxHeight: '60px' }}></Box>
          </ListItemAvatar>
          <ListItemText>
            <Typography sx={{ fontWeight: '600', fontSize: '2rem' }}>{t('payments.cardPayment')}</Typography>
          </ListItemText>
        </ListItemButton>
      )}
      {displayCashPayment && (
        <ListItemButton
          sx={{
            backgroundColor: selectedPaymentType === PaymentType.CASH ? '#9464FC' : '#F4F0FF',
            pl: 4,
            height: '134px',
            borderWidth: 4,
            borderStyle: 'solid',
            borderColor: selectedPaymentType === PaymentType.CASH ? '#FFFFFF' : '#FFFFFF',
            color: selectedPaymentType === PaymentType.CASH ? '#FFFFFF' : 'inherit',
            borderRadius: '5px',
          }}
          onClick={() => handlePaymentTypeClick(PaymentType.CASH)}
        >
          <ListItemAvatar sx={{ mr: 3 }}>
            <Box component="img" src="/images/cash_payment.png" sx={{ maxHeight: '60px' }}></Box>
          </ListItemAvatar>
          <ListItemText>
            <Typography sx={{ fontWeight: '600', fontSize: '2rem' }}>{t('payments.cashPayment')}</Typography>
          </ListItemText>
        </ListItemButton>
      )}
    </List>
  );

  const DesktopWizard = () => (
    <WizardSteps activeStep={0} disableBackButton={processing}>
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Typography sx={{ fontSize: '24px', fontWeight: '500', mr: 1 }}>
            <>{t('payments.amountSummary')}: </>
          </Typography>
          <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
            {' '}
            {basketSummary.amount} {basketSummary.currency}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Typography sx={{ fontSize: '24px', fontWeight: '700' }}>
            <>{t('payments.selectPaymentType')}</>
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <PaymentTypeList />
        </Grid>
        <Grid item xs={12} sx={{ mt: 3, display: 'flex', justifyContent: 'center', position: 'relative' }}>
          <RedButton onClick={handlePaymentButtonClick} disabled={processing}>
            <Typography>{t('payments.paymentButton').toString()}</Typography>
          </RedButton>
          {processing && (
            <CircularProgress
              size={34}
              sx={{
                color: '#FF4242',
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Grid>
      </Grid>
    </WizardSteps>
  );

  const MobileWizard = () => (
    <PaymentWrapper
      title={t('payments.paymentTypeStep')}
      onPaymentClick={handlePaymentButtonClick}
      isPaymentMethodSelected={selectedPaymentType !== null}
    >
      <Stack direction="column">
        <Stack
          direction={isDownSmallScreen ? 'column' : 'row'}
          sx={{ alignSelf: 'center', my: 2, alignItems: 'center' }}
          spacing={1}
        >
          <PaymentAmountText>
            <>{t('payments.amountSummary')}: </>
          </PaymentAmountText>
          <PaymentAmountText sx={{ fontWeight: 700 }}>
            {' '}
            {basketSummary.amount} {basketSummary.currency}
          </PaymentAmountText>
        </Stack>
        <PaymentTypeText sx={{ alignSelf: 'center' }}>
          <>{t('payments.selectPaymentType')}</>
        </PaymentTypeText>
        <PaymentTypeList />
      </Stack>
    </PaymentWrapper>
  );

  return (
    <>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      {clientLayout === Layout.Mobile ? <MobileWizard></MobileWizard> : <DesktopWizard></DesktopWizard>}
    </>
  );
};

export default PaymentWizard;
