import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { getBasketProducts } from '../../../modules/basket/selectors';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { BasketProduct } from '../../../modules/basket/models';
import { removeProduct } from '../../../modules/basket/slice';
import { TransitionGroup } from 'react-transition-group';
import Grid2 from '@mui/material/Unstable_Grid2';
import './SummaryBox.css';
import { PaymentButton } from '../../../components/styled-components';
import { useTranslation } from 'react-i18next';
const getVariantName = (product: BasketProduct) => {
  return product.product.variants.length <= 1
    ? ''
    : product.product.variants.find((x) => x.id === product.variantId)?.name;
};

const SummaryBox = () => {
  const { t } = useTranslation('pages');
  const dispatch = useAppDispatch();
  const products = useAppSelector((s) => getBasketProducts(s.basket));
  const handleRemoveProduct = (basketProduct: BasketProduct) => {
    dispatch(removeProduct(basketProduct));
  };

  return (
    <>
      <Stack direction={'column'} justifyContent={'space-between'} spacing={2} sx={{ height: 1 / 1 }}>
        <TransitionGroup component={Grid2} container>
          {products.map((p) => (
            <Collapse className={'summary-box-collapse'} key={p.product.id + p.variantId}>
              <Grid2 xs={6} display={'flex'} justifyContent={'left'} alignItems={'center'}>
                <Stack direction={'column'} spacing={1}>
                  <Typography>{p.product.name}</Typography>
                  <Typography sx={{ color: 'text.secondary' }}>{getVariantName(p)}</Typography>
                </Stack>
              </Grid2>
              <Grid2 xs={2} display={'flex'} alignItems={'center'}>
                <Typography align={'center'}>
                  {t('products.bottomsSheetDialog.quantity')}: {p.quantity}
                </Typography>
              </Grid2>
              <Grid2 xs={2} display={'flex'} alignItems={'center'}>
                <Typography align={'center'}>
                  {t('products.bottomsSheetDialog.amount')}: {p.amount} PLN
                </Typography>
              </Grid2>
              <Grid2 xs={2} display={'flex'} justifyContent={'right'} alignItems={'center'}>
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveProduct(p)}>
                  <DeleteIcon />
                </IconButton>
              </Grid2>
            </Collapse>
          ))}
        </TransitionGroup>

        <Stack justifyContent={'flex-end'} direction={'row'} spacing={2}>
          {/*
          <EloMainButton>Usuń wszystkie</EloMainButton>
*/}
          <PaymentButton>{t('products.bottomsSheetDialog.paymentButton')}</PaymentButton>
        </Stack>
      </Stack>
    </>
  );
};

export default SummaryBox;
