import { Box, Button, ListItemAvatar, ListItemButton, ListItemText, Typography, styled, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import { useState } from 'react';
import { BasketSummary } from '../../modules/basket/models';
import { TicketType } from '../../modules/tickets/models';
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';

const TicketNameText = styled(ListItemText)(({ theme }) => ({
  fontSize: '25px',
  '& span': { fontSize: '25px' },
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    '& span': { fontSize: '20px' },
  },
}));

const SummaryText = styled(Typography)(({ theme }) => ({
  fontSize: '25px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

interface TicketListItemProps {
  ticketSummary: BasketSummary;
  name: string;
  color: string;
  ticketType: TicketType;
  onDelete: (ticketType: TicketType) => void;
}

const TicketListItem = (props: TicketListItemProps) => {
  const { t } = useTranslation(['common']);
  const [showDelete, setShowDelete] = useState(false);
  return (
    <>
      {props.ticketSummary.quantity > 0 && (
        <Box sx={{ display: 'flex', my: 1, minHeight: '93px' }}>
          <ListItemButton
            sx={{ bgcolor: 'background.paper', left: showDelete ? -15 : 0, width: '100%' }}
            onClick={() => setShowDelete(!showDelete)}
          >
            <ListItemAvatar>
              <CircleIcon sx={{ fontSize: '15px' }} htmlColor={props.color}></CircleIcon>
            </ListItemAvatar>
            <Grid2 container sx={{ width: '100%' }}>
              <Grid2 xs={8}>
                <TicketNameText primary={props.name} />
              </Grid2>
              <Grid2 xs={2}>
                <SummaryText sx={{ mr: 5, fontWeight: 400 }}>
                  {props.ticketSummary.quantity} <>{t('common:tickets.units')}</>
                </SummaryText>
              </Grid2>
              <Grid2 xs={2}>
                <SummaryText sx={{ fontWeight: '600' }}>
                  {props.ticketSummary.amount} {props.ticketSummary.currency}
                </SummaryText>
              </Grid2>
            </Grid2>
          </ListItemButton>
          {showDelete && (
            <Button
              sx={{ backgroundColor: '#FF4242', minWidth: '70px' }}
              onClick={() => props.onDelete(props.ticketType)}
            >
              <DeleteIcon htmlColor="white"></DeleteIcon>
            </Button>
          )}
        </Box>
      )}
    </>
  );
};

export default TicketListItem;
