import { Box, Button, ButtonProps, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React from 'react';

interface OutlinedItemProps extends ButtonProps {
  iconUrl: string;
  description: string;
  pageUrl: string;
  fontColor: string;
}

const OutlinedItem = (props: OutlinedItemProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { sx, description, iconUrl, pageUrl, fontColor, ...other } = props;
  const getFontSize = (description: string): string => {
    if (matchesDownSM) {
      return description.length > 20 ? '1.1rem' : '1.6rem';
    }
    if (matchesDownMd) {
      return description.length > 20 ? '1.1rem' : '1.6rem';
    }
    return description.length > 20 ? '1.9rem' : '2.75rem';
  };
  return (
    <Button
      sx={{
        maxHeight: '25vmin',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        borderRadius: '4px',
        border: '1px solid #6260A8',
        ...sx,
      }}
      {...other}
      onClick={() => navigate(pageUrl)}
      variant="outlined"
    >
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={4} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <Box component="img" src={iconUrl} sx={{ maxWidth: matchesDownSM ? 45 : 85, objectFit: 'contain' }}></Box>
        </Grid>
        <Grid
          item
          xs={7}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            borderLeft: '2px dashed #6260A8',
            height: '100%',
            px: 2,
          }}
        >
          <Typography fontSize={getFontSize(description)} color={fontColor}>
            {description}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  );
};

export default OutlinedItem;
