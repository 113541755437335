import { Box, Button, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BarcodeDownPaymentMachine = () => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();
  return (
    <Stack
      direction={'column'}
      spacing={1}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ height: '100%' }}
    >
      <Stack direction={'column'} spacing={2} alignItems={'center'}>
        <Typography sx={{ color: '#EE4A83', fontWeight: 600, fontSize: '1.3rem', textAlign: 'center' }}>
          {t('barcodeScanner.parkingPaymentMachine.timeWarning')}
        </Typography>
        <Typography sx={{ color: '#4E4E4E', fontWeight: 600, fontSize: '1.375rem', textAlign: 'center' }}>
          {t('barcodeScanner.parkingPaymentMachine.acceptedPaymentMethods')}
        </Typography>
        <Box component={'img'} src={'/images/blik-credit-card.png'}></Box>
        <Typography sx={{ color: '#4E4E4E', fontWeight: 500, fontSize: '1.375rem', textAlign: 'center' }}>
          {t('barcodeScanner.parkingPaymentMachine.notAcceptedPaymentMethods')}
        </Typography>
        <Box component={'img'} src={'/images/tank-cards-disabled.png'}></Box>
        <Typography sx={{ color: '#4E4E4E', fontWeight: 500, fontSize: '1.375rem', textAlign: 'center' }}>
          {t('barcodeScanner.parkingPaymentMachine.timeWarning')}
        </Typography>
      </Stack>
      <Stack direction={'column'} spacing={2} alignItems={'center'}>
        <Typography sx={{ color: '#4E4E4E', fontWeight: 500, fontSize: '1.375rem', textAlign: 'center' }}>
          {t('barcodeScanner.parkingPaymentMachine.scanBarcodeInfo')}
        </Typography>
        <Typography sx={{ color: '#4E4E4E', fontWeight: 600, fontSize: '1.375rem', textAlign: 'center' }}>
          {t('barcodeScanner.parkingPaymentMachine.scanBarcodeDistanceInfo')}
        </Typography>
        <motion.div
          animate={{ y: [0, 20, 0, 15, 0], opacity: 1, scale: 1 }}
          transition={{
            duration: 3,
            delay: 0.3,
            ease: 'linear',
            repeat: Infinity,
            repeatDelay: 1,
          }}
        >
          <Box component={'img'} src={'/images/barcode-scanner-15cm.png'}></Box>
        </motion.div>
        <Button
          variant={'text'}
          sx={{ alignSelf: 'flex-end', color: '#EE4A83', fontSize: '1.2rem', textDecoration: 'underline' }}
          onClick={() => navigate('/tickets')}
        >
          {t('barcodeScanner.parkingPaymentMachine.lostTicketButton')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default BarcodeDownPaymentMachine;
