import CategoryFilter from '../../../components/CategoryFilter/CategoryFilter';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { getCategories, getSelectedCategoryId } from '../../../modules/products/selectors';
import { clearSelectedCategory, selectCategory } from '../../../modules/products/slice';

const ProductCategoryFilter = () => {
  const dispatch = useAppDispatch();
  const selectedCategoryId = useAppSelector((s) => getSelectedCategoryId(s.product));
  const categories = useAppSelector((s) => getCategories(s.product));
  const handleCategoryClick = (categoryId: string) => {
    dispatch(selectCategory(categoryId));
  };
  const handleClearSelectedCategory = () => {
    dispatch(clearSelectedCategory());
  };
  return <CategoryFilter categories={categories} selectedCategoryId={selectedCategoryId} onCategoryClick={handleCategoryClick} onClearSelectionClick={handleClearSelectedCategory} />;
};
export default ProductCategoryFilter;
