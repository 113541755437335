import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import { languages, namespaces } from './i18n.constants';
import pages_pl from '../assets/translations/pl/pages.json';
import common_pl from '../assets/translations/pl/common.json';
import components_pl from '../assets/translations/pl/components.json';
import pages_en from '../assets/translations/en/pages.json';
import common_en from '../assets/translations/en/common.json';
import components_en from '../assets/translations/en/components.json';
import common_ua from '../assets/translations/ua/common.json';
import components_ua from '../assets/translations/ua/components.json';
import pages_ua from '../assets/translations/ua/pages.json';

i18next.use(initReactI18next).init({
  debug: true,
  fallbackLng: languages.pl,
  resources: {
    pl: {
      common: common_pl,
      pages: pages_pl,
      components: components_pl,
    },
    en: {
      common: common_en,
      pages: pages_en,
      components: components_en,
    },
    ua: {
      common: common_ua,
      pages: pages_ua,
      components: components_ua,
    },
  },
});

export default i18next;
