import Grid2 from '@mui/material/Unstable_Grid2';
import { ReturnButton, SummaryText } from '../styled-components';
import { getReturnUrl } from '../../utils/navigationHelper';
import useDisplayToolbarReturnButton from '../../layout/layoutHooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface PageHeaderWithButtonProps {
  title: string;
  defaultReturnUrl?: string;
  onReturnClick?: () => void;
}
const PageHeaderWithButton = ({ title, defaultReturnUrl, onReturnClick }: PageHeaderWithButtonProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  let [searchParams] = useSearchParams();
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();

  const handleReturnClick = () => {
    if (onReturnClick) {
      onReturnClick();
    } else {
      navigate(getReturnUrl(searchParams, defaultReturnUrl ?? '/summary'));
    }
  };

  return (
    <Grid2 container>
      <Grid2 xs={displayToolbarReturnButton ? 0 : 3}>
        {!displayToolbarReturnButton && (
          <ReturnButton onClick={handleReturnClick} sx={{ mb: 4 }}>
            <>{t('buttons.back')}</>
          </ReturnButton>
        )}
      </Grid2>
      <Grid2
        xs={12}
        md={displayToolbarReturnButton ? 12 : 6}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <SummaryText sx={{ mb: 1 }}>{title}</SummaryText>
      </Grid2>
    </Grid2>
  );
};

export default PageHeaderWithButton;
