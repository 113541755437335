import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../types';
import { Category, Product, ProductStateModel } from './models';
import { fetchCategories, fetchProduct, fetchProducts } from './operations';

const initialState = {
  categories: [],
  products: [],
  allProducts: [],
  product: null,
  openBottomDialogSheet: false,
} as ProductStateModel;

const productSlice = createSlice({
  name: Slice.Tickets,
  initialState: initialState,
  reducers: {
    clearProduct: (state) => {
      state.product = null;
    },
    setFilterText: (state, action: PayloadAction<string>) => {
      state.filterText = action.payload;
      let products = applyCategoryFilter(state.allProducts, state.selectedCategoryId);
      products = applyTextFilter(products, action.payload);
      state.products = products;
    },
    selectCategory: (state, action: PayloadAction<string>) => {
      state.selectedCategoryId = action.payload;
      let products = applyCategoryFilter(state.allProducts, state.selectedCategoryId);
      products = applyTextFilter(products, state.filterText);
      state.products = products;
    },
    clearSelectedCategory: (state) => {
      state.selectedCategoryId = undefined;
      state.products = state.allProducts;
    },
    openBottomDialogSheet: (state, action: PayloadAction<Product>) => {
      console.log('Open drawer');
      state.openBottomDialogSheet = true;
    },
    closeBottomDialogSheet: (state) => {
      state.openBottomDialogSheet = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCategories.fulfilled, (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
      state.allProducts = action.payload;
    });
    builder.addCase(fetchProduct.fulfilled, (state, action: PayloadAction<Product | null>) => {
      state.product = action.payload;
    });
  },
});
const applyTextFilter = (products: Product[], filterText: string | undefined): Product[] => {
  if (filterText) {
    const regex = new RegExp(filterText, 'iu');
    return products.filter((p) => p.name.search(regex) > -1);
  }
  return products;
};
const applyCategoryFilter = (products: Product[], categoryId: string | undefined): Product[] => {
  if (categoryId) {
    return products
        .filter((p) => p.categories.findIndex((c) => c.id === categoryId) !== -1)
        .sort((p1, p2) => p1.name.localeCompare(p2.name));
  }
  return products;
};
export const { setFilterText, clearProduct, selectCategory, clearSelectedCategory } = productSlice.actions;
export default productSlice.reducer;
