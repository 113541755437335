import { Box, CircularProgress, Dialog, DialogTitle, Stack } from '@mui/material';
import React from 'react';

interface PrinterConnectingDialogProps {
  open: boolean;
}

const PrinterConnectingDialog = ({ open }: PrinterConnectingDialogProps) => {
  return (
    <Dialog open={open} sx={{ textAlign: 'center' }}>
      <DialogTitle>Łączenie z drukarką</DialogTitle>
      <Stack direction={'column'} spacing={3} sx={{ p: 3 }} justifyContent={'center'} alignItems="center">
        <CircularProgress disableShrink />
        <Box component="img" src={'/images/printer.png'}></Box>
      </Stack>
    </Dialog>
  );
};

export default PrinterConnectingDialog;
