import { Divider, Grid, Snackbar, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../utils/hooks';
import { getBasketTicketSummary } from '../../modules/basket/selectors';
import { GreenButton, RedButton } from '../../components/styled-components';
import { useNavigate } from 'react-router-dom';

interface BottomNavigationWrapperProps {
  returnUrl: string;
}

const BottomNavigationWrapper = ({ returnUrl }: BottomNavigationWrapperProps) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const basketSummary = useAppSelector((state) => getBasketTicketSummary(state.basket));
  const [open, setOpen] = useState<boolean>(false);

  const theme = useTheme();
  const isMDScreen = useMediaQuery(theme.breakpoints.up('md'));

  const handleClose = () => {
    setOpen(false);
  };
  const handleNextClick = async () => {
    if (basketSummary.quantity === 0) {
      setOpen(true);
    } else {
      navigate('/summary?r=/tickets');
    }
  };

  return (
    <>
      {/*<Box sx={{ position: 'fixed', bottom: 0, left: 0, backgroundColor: '#F2F2F2', px: 4, width: '100%' }}>*/}
      <Divider sx={{ borderBottom: '1px dashed #949494', my: 3 }}></Divider>
      <Grid container sx={{ pb: 1 }}>
        <Grid item xs={12} md={6}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ fontSize: '1.78rem', fontWeight: '500', color: '#4E4E4E' }}>
              <>{t('common:tickets.ticketCount')}</>
            </Typography>
            <Typography sx={{ fontSize: '1.78rem', color: '#9464FC', fontWeight: '500' }}>
              <>
                {basketSummary.quantity} {t('common:tickets.units')}
              </>
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography sx={{ fontSize: '2.14rem', color: '#4E4E4E' }}>
              <>{t('common:tickets.sum')}: </>
            </Typography>
            <Typography sx={{ fontSize: '2.42rem', color: '#9464FC', fontWeight: '700' }}>
              {basketSummary.amount.toFixed(2)} {basketSummary.currency}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} justifyContent={isMDScreen ? 'right' : 'center'} container>
          <Stack direction="row" spacing={2} alignItems="center">
            {isMDScreen && (
              <RedButton onClick={() => navigate(returnUrl)}>
                <>{t('common:buttons.back')}</>
              </RedButton>
            )}
            <GreenButton onClick={handleNextClick} disabled={basketSummary.quantity === 0}>
              <>{t('common:buttons.next')}</>
            </GreenButton>
          </Stack>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={3000}
        onClose={handleClose}
        open={open}
        message={t('common:tickets.selectTicket').toString()}
      />
    </>
  );
};

export default BottomNavigationWrapper;
