import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../types';
import { InvoiceData, Ticket, TicketType } from '../tickets/models';
import { BasketProduct, BasketStateModel, BasketTicket } from './models';
import { Product, ProductVariant } from '../products/models';

const initialState = {
  basketTickets: [] as BasketTicket[],
  basketProducts: [] as BasketProduct[],
} as BasketStateModel;

const basketSlice = createSlice({
  name: Slice.Basket,
  initialState: initialState,
  reducers: {
    increment: (state, action: PayloadAction<Ticket>) => {
      console.log('Increment ticket', action.payload);
      const ticket = action.payload;
      const basketTicket = state.basketTickets.find((x) => x.ticket.variantId === ticket.variantId);
      const minQuantity = ticket.minQuantity ?? 1;
      if (basketTicket) {
        basketTicket.quantity++;
        basketTicket.amount += ticket.price.amount;
      } else {
        const newBasketTicket = {
          ticket: ticket,
          amount: ticket.price.amount * minQuantity,
          quantity: minQuantity,
        } as BasketTicket;
        state.basketTickets.push(newBasketTicket);
      }
      console.log('End incrementation ticket', JSON.stringify(state.basketTickets));
    },
    decrement: (state, action: PayloadAction<Ticket>) => {
      const ticket = action.payload;
      const basketTicket = state.basketTickets.find((x) => x.ticket.variantId === ticket.variantId);
      if (basketTicket) {
        basketTicket.quantity--;
        if (basketTicket.quantity > 0) {
          if (ticket.minQuantity != null && ticket.minQuantity > 1 && basketTicket.quantity < ticket.minQuantity) {
            state.basketTickets.splice(
              state.basketTickets.findIndex((x) => x.ticket.variantId === ticket.variantId),
              1
            );
          } else {
            basketTicket.amount -= ticket.price.amount;
          }
        } else {
          state.basketTickets.splice(
            state.basketTickets.findIndex((x) => x.ticket.variantId === ticket.variantId),
            1
          );
        }
      }
    },
    incrementProduct: (state, action: PayloadAction<[ProductVariant, Product]>) => {
      console.log('Increment product', action.payload);
      const productVariant = action.payload[0];
      const product = action.payload[1];
      const basketProduct = state.basketProducts.find((x) => x.variantId === productVariant.id);
      if (basketProduct) {
        basketProduct.quantity++;
        basketProduct.amount += productVariant.price.amount;
      } else {
        const newBasketProduct = {
          product: product,
          amount: productVariant.price.amount,
          quantity: 1,
          variantId: productVariant.id,
        } as BasketProduct;
        state.basketProducts.push(newBasketProduct);
      }
    },
    decrementProduct: (state, action: PayloadAction<ProductVariant>) => {
      const productVariant = action.payload;
      const basketProduct = state.basketProducts.find((x) => x.variantId === productVariant.id);
      if (basketProduct) {
        basketProduct.quantity--;
        if (basketProduct.quantity > 0) {
          basketProduct.amount -= productVariant.price.amount;
        } else {
          state.basketProducts.splice(
            state.basketProducts.findIndex((x) => x.variantId === productVariant.id),
            1
          );
        }
      }
    },
    addBasketTicket: (state, action: PayloadAction<BasketTicket>) => {
      console.log('Add basket ticket', action.payload);
      const basketTicket = action.payload;
      if (basketTicket) {
        state.basketTickets.push(basketTicket);
      }
    },
    addBasketProduct: (state, action: PayloadAction<BasketProduct>) => {
      const basketProduct = action.payload;
      if (basketProduct) {
        state.basketProducts.push(basketProduct);
      }
    },
    addOrUpdateBasketTicket: (state, action: PayloadAction<BasketTicket>) => {
      const basketTicket = action.payload;

      if (basketTicket) {
        const currentBasketTicketIndex = state.basketTickets.findIndex(
          (x) => x.ticket.variantId === basketTicket.ticket.variantId
        );
        if (currentBasketTicketIndex >= 0) {
          state.basketTickets.splice(currentBasketTicketIndex, 1);
        }

        state.basketTickets.push(basketTicket);
      }
    },
    removeBasketTicket: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.basketTickets.splice(
        state.basketTickets.findIndex((x) => x.id === id),
        1
      );
    },
    deleteTicketGroup: (state, action: PayloadAction<TicketType>) => {
      const ticketType = action.payload;
      state.basketTickets = state.basketTickets.filter((x) => x.ticket.type !== ticketType);
    },
    clearBasket: (state) => {
      console.log('Clear basket');
      state.basketTickets = [];
      state.basketProducts = [];
      state.invoiceData = undefined;
    },
    removeParkingTicket: (state) => {
      console.log('Clear parking tickets from basket', state.basketTickets.length);
      const index = state.basketTickets.findIndex((x) => x.ticket.type === TicketType.PARKING);
      if (index !== -1) {
        state.basketTickets.splice(index, 1);
      }
      console.log('Clear parking tickets from basket - after', state.basketTickets.length);
    },
    addCompanyData: (state, action: PayloadAction<InvoiceData>) => {
      console.log(action.payload);
      state.invoiceData = action.payload;
    },
    removeProduct: (state, action: PayloadAction<BasketProduct>) => {
      console.log('Remove from basket', action.payload);
      state.basketProducts.splice(
        state.basketProducts.findIndex(
          (p) => p.variantId === action.payload.variantId && p.product.id === action.payload.product.id
        ),
        1
      );
    },
  },
});

export const {
  increment,
  decrement,
  deleteTicketGroup,
  clearBasket,
  addBasketTicket,
  addOrUpdateBasketTicket,
  removeBasketTicket,
  addCompanyData,
  addBasketProduct,
  incrementProduct,
  decrementProduct,
  removeProduct,
  removeParkingTicket,
} = basketSlice.actions;
export default basketSlice.reducer;
