import PriceTicketTileWrapper from './PriceTicketTileWrapper';
import { Button, ButtonBase, Stack, Typography } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { iconStyles, TicketQuantity } from '../styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import React from 'react';
import { Ticket, TicketType } from '../../../modules/tickets/models';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { getBasketTicketSummary } from '../../../modules/basket/selectors';
import { toWithReturnParam } from '../../../utils/navigationHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { decrement } from '../../../modules/basket/slice';

interface ParkingTicketTileProps {
  ticket: Ticket;
}
const ParkingTicketTile = ({ ticket }: ParkingTicketTileProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const basketTicketSummary = useAppSelector((state) =>
    getBasketTicketSummary(state.basket, undefined, ticket.variantId)
  );
  const handleClick = () => {
    if (ticket.question !== null && basketTicketSummary.quantity === 0) {
      console.log('Incrementing ticket: ' + JSON.stringify(ticket));
      if (ticket.type === TicketType.PARKING) {
        navigate(toWithReturnParam(location, `/vehicle?variantId=${ticket.variantId}`));
      }
      return;
    }
  };

  const handleDecrementClick = () => {
    dispatch(decrement(ticket));
  };

  return (
    <PriceTicketTileWrapper ticket={ticket} onTileClick={handleClick}>
      {basketTicketSummary?.quantity > 0 ? (
        <Stack direction="column" alignItems={'center'}>
          <Stack direction={'row'} spacing={{ xs: 2, md: 0, lg: 2 }}>
            <>
              <Button size="small" onClick={handleDecrementClick}>
                <RemoveCircleOutlineIcon sx={iconStyles} htmlColor="white" />
              </Button>
              <TicketQuantity sx={{ alignSelf: 'center' }} align="center">
                {basketTicketSummary?.quantity ?? 0}
              </TicketQuantity>
            </>
          </Stack>
        </Stack>
      ) : null}
    </PriceTicketTileWrapper>
  );
};

export default ParkingTicketTile;
