import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VioletButton } from '../../components/styled-components';

interface PrinterWarningDialogProps {
  open: boolean;
  handleClose: () => void;
}

const PrinterWarningDialog = ({ open, handleClose }: PrinterWarningDialogProps) => {
  const { t } = useTranslation(['common']);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <>{t('common:warnings.disconnectedPrinter')}</>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2} sx={{ justifyContent: 'center', pt: 3 }}>
          <Box component="img" src="/images/printer.png" sx={{ objectFit: 'contain' }}></Box>
          <Box component="img" src="/images/warning.png" sx={{ objectFit: 'contain' }}></Box>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 3, justifyContent: 'center' }}>
        <VioletButton onClick={handleClose}>
          <>{t('common:buttons.close')}</>
        </VioletButton>
      </DialogActions>
    </Dialog>
  );
};

export default PrinterWarningDialog;
