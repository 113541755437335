import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  getEmbeddedLprScanner,
  getEmbeddedQrScanner,
  getEmbeddedQrScannerLocation,
  getPrimaryMode,
} from '../modules/layout/selectors';
import { AppDispatch, RootState } from '../store/store';
import { PrimaryMode, ScannerLocation } from './device';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppReturnLink = (): string => {
  const primaryMode = useAppSelector((x) => getPrimaryMode(x.layout));

  if (primaryMode == PrimaryMode.Sales) {
    return '/tickets';
  }
  if (primaryMode == PrimaryMode.TicketScan) {
    return '/scan';
  }

  return '/';
};

export const useScannerIndicator = (): boolean => {
  const embeddedQrScanner = useAppSelector((x) => getEmbeddedQrScanner(x.layout));
  const embeddedLprScanner = useAppSelector((x) => getEmbeddedLprScanner(x.layout));
  const scannerLocation = useAppSelector((x) => getEmbeddedQrScannerLocation(x.layout));

  return (embeddedQrScanner || embeddedLprScanner) && scannerLocation != ScannerLocation.none;
};
