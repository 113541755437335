import { Paper, Grid, Stack, useTheme, useMediaQuery } from '@mui/material';
import {
  TicketLongNameTypography,
  TicketNameTypography,
  ButtonsStackStyles,
  displayPrice,
  ProductPriceText,
} from '../styles';
import { ProductVariant } from '../../../modules/products/models';

type ProductBoxWrapperProps = {
  productVariant: ProductVariant;
  children: JSX.Element;
};

const ProductBoxWrapper = ({ productVariant, children }: ProductBoxWrapperProps) => {
  const theme = useTheme();
  const isUpMdScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isUpSmScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Paper sx={{ backgroundColor: '#6260A8', color: 'white', p: 2, margin: 0 }} elevation={3}>
      <Grid container>
        <Grid item xs={12} md={7}>
          <Stack direction={{ xs: 'row', md: 'column' }} spacing={1} justifyContent={'left'}>
            <Stack
              direction="row"
              sx={{ borderBottom: isUpSmScreen ? 1 : 0, display: 'inline-flex', justifyContent: 'left', pb: 1 }}
            >
              {productVariant.name.length > 60 ? (
                <TicketLongNameTypography sx={{ fontWeight: 600 }}>{productVariant.name}</TicketLongNameTypography>
              ) : (
                <TicketNameTypography sx={{ marginLeft: 0, fontWeight: 600 }}>
                  {productVariant.name}
                </TicketNameTypography>
              )}
            </Stack>
            {isUpMdScreen ? (
              <ProductPriceText>
                {displayPrice(productVariant.price.amount)} {productVariant.price.currency}
              </ProductPriceText>
            ) : null}
          </Stack>
        </Grid>
        <Grid item xs={12} md={5} alignItems={'center'} justifyContent={'right'}>
          <Stack direction="row" justifyContent={isUpMdScreen ? 'right' : 'space-between'} alignItems={'center'}>
            {isUpMdScreen ? null : (
              <ProductPriceText>
                {displayPrice(productVariant.price.amount)} {productVariant.price.currency}
              </ProductPriceText>
            )}
            <Stack direction="row" sx={ButtonsStackStyles}>
              {children}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductBoxWrapper;
