import { ReactComponent as ScannerPosition } from './scanner-position.svg';
import { ReactComponent as EnFlag } from './en-flag.svg';
import { ReactComponent as PlFlag } from './pl-flag.svg';
import { ReactComponent as UaFlag } from './ua-flag.svg';
import { ReactComponent as QrCodeScanner } from './qr-code-scanner.svg';
import { ReactComponent as ScannerArrows } from './scanner-arrows.svg';

const Icons = {
  ScannerPosition,
  EnFlag,
  PlFlag,
  UaFlag,
  QrCodeScanner,
  ScannerArrows,
};
export default Icons;
