import { styled, Typography } from '@mui/material';

export const ConfirmationContent = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.125rem',
    lineHeight: '24px',
  },
  fontSize: '26px',
  fontWeight: '700',
  lineHeight: '45px',
}));
