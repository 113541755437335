import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';

interface ValidationStatusProps {
  isValid: boolean;
}

const ValidationStatus = ({ isValid }: ValidationStatusProps) => {
  return isValid ? (
    <CheckCircleOutlineOutlinedIcon sx={{ color: 'green' }} />
  ) : (
    <DoNotDisturbOnOutlinedIcon sx={{ color: 'red' }} />
  );
};

export default ValidationStatus;
