import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PlateForm from '../components/PlateForm';
import { getReturnUrl, toWithReturnParam } from '../utils/navigationHelper';
import { useAppDispatch } from '../utils/hooks';
import { removeParkingTicket } from '../modules/basket/slice';

const ParkingPlate = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!searchParams.get('variantId')) {
      console.error(`Cannot get parking ticket for empty variantId`);
    }
  }, []);

  const handleConfirmClick = (plate: string) => {
    navigate(toWithReturnParam(location, `/vehicle/ticket/${searchParams.get('variantId')}/${plate.toUpperCase()}`));
  };

  const handleReturnClick = () => {
    dispatch(removeParkingTicket());
    navigate(getReturnUrl(searchParams, '/'));
  };

  return (
    <PlateForm
      handleConfirm={handleConfirmClick}
      confirmButtonText={t('plates.add')}
      handleReturn={handleReturnClick}
    ></PlateForm>
  );
};

export default ParkingPlate;
