import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OtherTicketsTile from '../../components/TicketButton/Tiles/OtherTicketsTile';
import PriceTicketTile from '../../components/TicketButton/Tiles/PriceTicketTile';
import { getChildTicket, getKDRTicket, hasFreeTickets } from '../../modules/tickets/selectors';
import { useAppSelector } from '../../utils/hooks';
import React from 'react';

const FreeTicketsSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const kdrTicket = useAppSelector((state) => getKDRTicket(state.tickets));
  const childTicket = useAppSelector((state) => getChildTicket(state.tickets));
  const anyFreeTickets = useAppSelector((state) => hasFreeTickets(state.tickets));
  const matchesDownLg = useMediaQuery(theme.breakpoints.down('lg'));

  const preDisplayedView = (
    <>
      {/*<Divider color="#CCCCCC" sx={{ mt: 5, mb: 3 }}></Divider>
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ fontSize: '1.42rem', color: '#4E4E4E' }}>
          <>{t('common:tickets.freeticketsHeader')}</>
        </Typography>
      </Box>*/}

      <Grid container spacing={4} sx={{ pt: 4 }}>
        {kdrTicket !== undefined ? (
          <Grid item xs={12} lg={6}>
            <PriceTicketTile ticket={kdrTicket}></PriceTicketTile>
          </Grid>
        ) : null}
        {childTicket !== undefined ? (
          <Grid item xs={12} lg={6}>
            <PriceTicketTile ticket={childTicket}></PriceTicketTile>
          </Grid>
        ) : null}
        <Grid item xs={12} lg={6}>
          <OtherTicketsTile selectClick={() => navigate('/tickets/free?r=/tickets')}></OtherTicketsTile>
        </Grid>
      </Grid>
    </>
  );

  const groupView = (
    <>
      <Grid container spacing={4} sx={{ mt: 0 }}>
        <Grid item xs={12} lg={6}>
          <OtherTicketsTile selectClick={() => navigate('/tickets/free?r=/tickets')}></OtherTicketsTile>
        </Grid>
      </Grid>
    </>
  );

  return anyFreeTickets ? matchesDownLg ? groupView : preDisplayedView : <></>;
};

export default FreeTicketsSection;
