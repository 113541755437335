import { Divider, LinearProgress, linearProgressClasses, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import format from 'date-fns/format';
import { useAppSelector } from '../../utils/hooks';
import { getTodayStats } from '../../modules/tickets/selectors';
import { useTranslation } from 'react-i18next';

const TodayStatBar = () => {
  const { t } = useTranslation(['common']);
  const stats = useAppSelector((s) => getTodayStats(s.tickets));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const soldTickets = (): number => {
    if (stats) {
      return stats.originalAvailability - stats.availableTickets;
    }
    return 0;
  };

  const getRange = (): number => {
    if (stats) {
      if (soldTickets() === 0) {
        return 0;
      }

      return Math.round(soldTickets() / stats.originalAvailability);
    }
    return 0;
  };

  return (
    <>
      {stats && (
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
            <Typography>
              {format(Date.parse(stats.date), 'dd.MM.yyyy')} - {t('common:tickets.statsSold').toString()}
            </Typography>
            <Typography>
              {soldTickets()}/{stats.originalAvailability} {t('common:tickets.originalAvailabilityTicketsText').toString()}
            </Typography>
          </Stack>
          {!isSmallScreen && <BorderLinearProgress variant="determinate" value={getRange()} />}
          <Divider></Divider>
        </Stack>
      )}
    </>
  );
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#EE4A83',
  },
}));

export default TodayStatBar;
