import { Button, styled, Typography, Alert } from '@mui/material';

import { ButtonColor } from '../layout/colors';
import { grey } from '@mui/material/colors';

export const SummaryText = styled(Typography)({ fontSize: '1.8rem', color: '#5E5E5E', px: 4 });

const BaseReturnButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.72rem',
    fontWeight: 500,
    borderRadius: '1rem',
    width: '5rem',
    height: '2rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
    fontWeight: 700,
    borderRadius: '3rem',
    width: '210px',
    height: '69px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    fontWeight: 700,
    borderRadius: '3rem',
    width: '13rem',
    height: '5rem',
  },
  color: 'white',
  px: 4,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  fontFamily: 'Roboto, sans-serif',
}));

export const ReturnButton = styled(BaseReturnButton)(({ theme }) => ({
  backgroundColor: ButtonColor.Red,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.RedHover,
  },
}));

export const ToolbarReturnButton = styled(Button)(({ theme }) => ({
  backgroundColor: ButtonColor.Red,
  '&:hover': {
    backgroundColor: ButtonColor.RedHover,
  },
  color: 'white',
  fontSize: '0.72rem',
  fontWeight: 500,
  borderRadius: '1rem',
  width: '5rem',
  height: '2rem',
}));

const BaseButton = styled(Button)(({ theme }) => ({
  height: 67,
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 700,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '3rem',
  fontFamily: 'Roboto, sans-serif',
  minWidth: '20vw',
  [theme.breakpoints.down('lg')]: {
    height: '7vh',
  },
  [theme.breakpoints.up('lg')]: {
    height: '8vh',
  },
  [theme.breakpoints.down('md')]: {
    height: 75,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: '1.125rem',
    display: 'inline-flex',
    minWidth: '30vw',
  },
}));

export const PaymentButton = styled(BaseButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: { width: '100%' },
  fontSize: '1.5rem',
  fontWeight: 700,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '3rem',
  fontFamily: 'Roboto, sans-serif',
  width: '30vw',
  height: '5rem',
  '&:disabled': {
    backgroundColor: grey[400],
    color: grey[800],
  },
  backgroundColor: ButtonColor.Green,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.GreenHover,
  },
}));

export const AddCompanyDataButton = styled(BaseButton)({
  backgroundColor: ButtonColor.Violet,
  '&:hover': {
    backgroundColor: ButtonColor.VioletHover,
  },
});

export const GreyButton = styled(BaseButton)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minWidth: 250,
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  backgroundColor: ButtonColor.Grey,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.GreyHover,
  },
}));

export const BlueButton = styled(BaseButton)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minWidth: 250,
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  backgroundColor: ButtonColor.Blue,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.BlueHover,
  },
}));

export const GreenButton = styled(BaseButton)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    minWidth: 250,
    fontSize: '1.125rem',
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  height: 67,
  backgroundColor: ButtonColor.Green,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.GreenHover,
  },
}));

export const VioletButton = styled(BaseButton)(({ theme }) => ({
  height: 67,
  backgroundColor: ButtonColor.Violet,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.VioletHover,
  },
}));

export const VioletButtonSmall = styled(BaseButton)(({ theme }) => ({
  height: '6vh',
  backgroundColor: ButtonColor.Violet,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.VioletHover,
  },
}));

export const ScanNextTicketButton = styled(VioletButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minWidth: 290,
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  height: 67,
}));

export const RedButton = styled(BaseButton)(({ theme }) => ({
  backgroundColor: ButtonColor.Red,
  color: 'white',
  '&:hover': {
    backgroundColor: ButtonColor.RedHover,
  },
  [theme.breakpoints.up('lg')]: {
    minWidth: 200,
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const BergAlert = styled(Alert)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5em',
  },
}));

export const MiddleWidthButton = styled(PaymentButton)({
  backgroundColor: ButtonColor.Violet,
  width: '70%',
  '&:hover': {
    backgroundColor: ButtonColor.VioletHover,
  },
});
