import { Paper, Grid, Stack, useTheme, useMediaQuery, Box } from '@mui/material';
import { Ticket, TicketType } from '../../../modules/tickets/models';
import UnavailableBox from '../../../pages/tickets/UnavailableBox';
import {
  getTicketBgColor,
  FreeTicketNameTypography,
  TicketLongNameTypography,
  TicketNameTypography,
  PriceText,
  getTicketProps,
  displayPrice,
} from '../styles';
import { ReactNode } from 'react';
import { styled } from '@mui/system';

const ButtonsStack = styled(Stack)(({ theme }) => ({
  alignContent: 'right',
  justifyContent: 'right',
  '& Button:disabled svg': { color: 'rgba(0, 0, 0, 0.26)' },
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

type PriceTicketTileWrapperProps = {
  ticket: Ticket;
  children: ReactNode;
  onTileClick?: () => void;
};

const PriceTicketTileWrapper = ({
  children,
  ticket: { available, name, price, type },
  onTileClick,
}: PriceTicketTileWrapperProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));

  const getTicketIconSrc = () => {
    switch (type) {
      case TicketType.NORMAL:
        return <Box src="/images/ticket_normal.png" component="img" sx={getTicketProps(isSmallScreen)}></Box>;
      case TicketType.FREE:
        return <Box src="/images/ticket_free.png" component="img" sx={getTicketProps(isSmallScreen)}></Box>;
      case TicketType.REDUCED:
        return <Box src="/images/ticket_reduced.png" component="img" sx={getTicketProps(isSmallScreen)}></Box>;
      case TicketType.PARKING:
        return <Box src="/images/ticket_normal.png" component="img" sx={getTicketProps(isSmallScreen)}></Box>;
      default:
        return null;
    }
  };

  return (
    <Paper
      sx={{ backgroundColor: getTicketBgColor(type), color: 'white', p: 2, margin: 0 }}
      elevation={3}
      onClick={onTileClick}
    >
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <Grid item xs={12} md={available > 0 && children == null ? 12 : 7}>
          <Stack direction={{ xs: 'row', md: 'column' }} spacing={1} justifyContent="space-between">
            <Stack
              direction="row"
              sx={{
                borderBottom: isMdScreen ? 1 : 0,
                justifyContent: 'left',
                pb: 1,
                maxWidth: isMdScreen ? 'auto' : '70%',
              }}
            >
              {getTicketIconSrc()}
              {type === TicketType.FREE ? (
                <FreeTicketNameTypography>{name}</FreeTicketNameTypography>
              ) : name.length > 60 ? (
                <TicketLongNameTypography>{name}</TicketLongNameTypography>
              ) : (
                <TicketNameTypography>{name}</TicketNameTypography>
              )}
            </Stack>
            <PriceText noWrap>
              {displayPrice(price.amount)} {price.currency}
            </PriceText>
          </Stack>
        </Grid>
        {available === 0 || children !== null ? (
          <Grid container item xs={12} md={5} sx={{ alignContent: 'center' }}>
            {available === 0 ? (
              <Grid container direction="column" alignContent="flex-end" justifyItems="center">
                <UnavailableBox ticketType={type}></UnavailableBox>
              </Grid>
            ) : (
              <Grid item container direction="column" sx={{ display: 'flex', justifyContent: 'right' }}>
                <ButtonsStack direction="row">{children}</ButtonsStack>
              </Grid>
            )}
          </Grid>
        ) : null}
      </Stack>
    </Paper>
  );
};

export default PriceTicketTileWrapper;
