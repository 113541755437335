import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VioletButton } from '../../components/styled-components';

interface InformationDialogProps {
  handleClose: () => void;
  message: string;
  open: boolean;
}
const InformationDialog = ({ handleClose, open, message }: InformationDialogProps) => {
  const { t } = useTranslation(['common']);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <>{t('common:ticketStatus.statusTitle')}</>
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions sx={{ py: 3, justifyContent: 'center' }}>
        <VioletButton onClick={handleClose}>
          <>{t('common:buttons.close')}</>
        </VioletButton>
      </DialogActions>
    </Dialog>
  );
};

export default InformationDialog;
