import { Stack, styled, useMediaQuery, useTheme, SxProps, Theme } from '@mui/material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AddCompanyDataButton, PaymentButton, RedButton } from './styled-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getReturnUrl, toWithReturnParam } from '../utils/navigationHelper';
import { useTranslation } from 'react-i18next';
import PageHeader from './PageHeader/PageHeader';
import useDisplayToolbarReturnButton from '../layout/layoutHooks';
import { useAppSelector } from '../utils/hooks';
import { hasOnlyParkingTickets } from '../modules/tickets/selectors';

const PlusIcon = styled(AddCircleIcon)({
  // fontSize: '2rem',
  width: '35px',
  height: '35px',
  // '&>:nth-of-type(1)': {
  //   fontSize: '2rem',
  // },
});

interface PaymentWrapperProps {
  children: JSX.Element;
  title: string;
  onPaymentClick: () => void;
  isPaymentMethodSelected: boolean;
  displayCompanyDetailsButton?: boolean;
}

const PaymentWrapper = ({
  children,
  displayCompanyDetailsButton,
  onPaymentClick,
  title,
  isPaymentMethodSelected,
}: PaymentWrapperProps) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();
  const theme = useTheme();
  const isUpMDScreen = useMediaQuery(theme.breakpoints.up('md'));
  const mdStyles = { width: '100%', position: 'fixed', bottom: 0, left: 0, px: 4, pb: 5 } as SxProps<Theme>;
  const xsStyles = { width: '100%', pb: 2 } as SxProps<Theme>;
  const onlyParkingTickets = useAppSelector((s) => hasOnlyParkingTickets(s.tickets));
  const handlePaymentClick = () => {
    onPaymentClick();
  };

  const handleAddCompanyData = () => {
    navigate(toWithReturnParam(location, '/summary/company-details'));
  };

  return (
    <>
      <Stack direction="column" sx={{ py: 2 }} spacing={2}>
        <PageHeader title={title}></PageHeader>
        {children}
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={'space-between'}
          sx={isUpMDScreen ? mdStyles : xsStyles}
          spacing={2}
        >
          {!displayToolbarReturnButton && (
            <RedButton onClick={() => navigate(getReturnUrl(searchParams, '/summary'))}>
              <>{t('buttons.back')}</>
            </RedButton>
          )}
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            {displayCompanyDetailsButton && (
              <AddCompanyDataButton
                onClick={handleAddCompanyData}
                sx={{ alignSelf: 'center', width: '60%' }}
                startIcon={<PlusIcon viewBox="0 0 24 24" />}
              >
                <>{t('summary.companyData')}</>
              </AddCompanyDataButton>
            )}
            <PaymentButton onClick={handlePaymentClick} disabled={!isPaymentMethodSelected}>
              <>{t('buttons.pay')}</>
            </PaymentButton>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default PaymentWrapper;
