import { Grid } from '@mui/material';
import PriceTicketTile from '../../components/TicketButton/Tiles/PriceTicketTile';
import { Ticket } from '../../modules/tickets/models';

interface TicketListProps {
  tickets: Ticket[];
}

const TicketList = (props: TicketListProps) => {
  return (
    <>
      {props.tickets.map((ticket) => {
        return (
          <Grid key={ticket.variantId} item xs={12} md={6}>
            <PriceTicketTile key={ticket.variantId} ticket={ticket}></PriceTicketTile>
          </Grid>
        );
      })}
    </>
  );
};

export default TicketList;
