import SearchBar from '../../../components/SearchBar/SearchBar';
import { useAppDispatch } from '../../../utils/hooks';
import { setFilterText } from '../../../modules/products/slice';

const ProductSearchBar = () => {
  const dispatch = useAppDispatch();
  const handleTextChange = (text: string) => {
    dispatch(setFilterText(text));
  };
  return <SearchBar onTextChange={handleTextChange}></SearchBar>;
};
export default ProductSearchBar;
