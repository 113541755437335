import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../types';
import { StatModel, Ticket, TicketStateModel } from './models';
import { fetchStats, fetchTickets } from './operations';

const initialState = {
  tickets: [] as Ticket[],
  stats: [] as StatModel[],
} as TicketStateModel;

const ticketSlice = createSlice({
  name: Slice.Tickets,
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchTickets.fulfilled, (state, action: PayloadAction<Ticket[]>) => {
      state.tickets = action.payload;
    });
    builder.addCase(fetchStats.fulfilled, (state, action: PayloadAction<StatModel[]>) => {
      state.stats = action.payload;
    });
  },
});

export const {} = ticketSlice.actions;
export default ticketSlice.reducer;
