import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../types';
import { OrderStatus, PaymentStateModel } from './models';
import { createOrder, getOrder } from './operations';

const initialState = {
  orderStatus: null,
} as PaymentStateModel;

const paymentSlice = createSlice({
  name: Slice.Payment,
  initialState: initialState,
  reducers: {
    clearOrderStatus: (state) => {
      state.orderStatus = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(createOrder.fulfilled, (state, action: PayloadAction<OrderStatus | null>) => {
      console.log('[Payment slice] Setting orderStatus ' + action.payload?.orderId);
      state.orderStatus = action.payload;
    });
    builder.addCase(getOrder.fulfilled, (state, action: PayloadAction<OrderStatus | null>) => {
      console.log('[Payment slice] Setting orderStatus ' + action.payload?.orderId);
      state.orderStatus = action.payload;
    });
  },
});

export const { clearOrderStatus } = paymentSlice.actions;
export default paymentSlice.reducer;
