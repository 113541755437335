import { Category, Product, ProductStateModel } from './models';

export const getCategories = (state: ProductStateModel): Category[] => {
  return state.categories;
};

export const getProducts = (state: ProductStateModel): Product[] => {
  return state.products;
};

export const getProduct = (state: ProductStateModel): Product | null => {
  return state.product;
};

export const getSelectedCategoryId = (state: ProductStateModel): string | undefined => {
  return state.selectedCategoryId;
};

export const getOpenBottomDialogSheet = (state: ProductStateModel): boolean => {
  return state.openBottomDialogSheet;
};
