import { motion } from 'framer-motion';
import Icons from '../../assets/icons';
import { Box, Stack, SxProps } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../utils/hooks';
import {
  getEmbeddedQrScanner,
  getEmbeddedQrScannerIconSize,
  getEmbeddedQrScannerLocation,
  getEmbeddedQrScannerType,
  getEmbeddedQrScannerVerticalLocation,
} from '../../modules/layout/selectors';
import { ScannerIconSize, ScannerLocation, ScannerType, ScannerVerticalLocation } from '../../utils/device';

const defaultX = [0, 0, 0, 0, 0];
const defaultY = [0, 0, 0, 0, 0];
const topPositionStyles: SxProps = { top: 0, width: 'calc(100% - 48px)' };
const bottomPositionStyles: SxProps = { bottom: 0, width: 'calc(100% - 48px)', paddingBottom: '1vh' };
const middlePositionStyles: SxProps = { top: '50%', width: 'calc(100% - 48px)' };

const ScannerIndicator = () => {
  const scannerLocation = useAppSelector((x) => getEmbeddedQrScannerLocation(x.layout));
  const scannerVerticalLocation = useAppSelector((x) => getEmbeddedQrScannerVerticalLocation(x.layout));
  const scannerIconSize = useAppSelector((x) => getEmbeddedQrScannerIconSize(x.layout));
  const embeddedQrScanner = useAppSelector((x) => getEmbeddedQrScanner(x.layout));
  const embeddedQrScannerType = useAppSelector((x) => getEmbeddedQrScannerType(x.layout));
  const [rotate, setRotate] = useState<string>('rotate(0)');
  const [x, setX] = useState<number[]>(defaultX);
  const [y, setY] = useState<number[]>(defaultY);
  const [position, setPosition] = useState<string>('center');

  useEffect(() => {
    if (embeddedQrScanner) {
      switch (scannerLocation) {
        case ScannerLocation.left:
          setRotate('rotate(90)');
          setX([0, -10, 0, -5, 0]);
          setPosition('left');
          break;
        case ScannerLocation.right:
          setRotate('rotate(-90)');
          setX([0, 10, 0, 5, 0]);
          setPosition('right');
          break;
        case ScannerLocation.top:
          setRotate('rotate(180)');
          setX(defaultX);
          setPosition('center');
          break;
        default:
          setRotate('rotate(0)');
          setX(defaultX);
          setPosition('center');
      }
      switch (scannerVerticalLocation) {
        case ScannerVerticalLocation.bottom:
          setY(defaultY);
          break;
        case ScannerVerticalLocation.middle:
          setY(defaultY);
          break;
        case ScannerVerticalLocation.top:
          setY([0, -10, 0, -5, 0]);
          break;
        default:
          setY([0, 10, 0, 5, 0]);
      }
    }
  }, [scannerLocation, embeddedQrScanner]);

  const getStyles = (): SxProps => {
    const defaultSxProps: SxProps = { display: 'flex', justifyContent: position, position: 'absolute' };
    console.log('getStyles - scannerVerticalLocation', scannerVerticalLocation);
    switch (scannerVerticalLocation) {
      case ScannerVerticalLocation.top:
        return { ...defaultSxProps, ...topPositionStyles };
      case ScannerVerticalLocation.middle:
        return { ...defaultSxProps, ...middlePositionStyles };
      case ScannerVerticalLocation.bottom:
        return { ...defaultSxProps, ...bottomPositionStyles };
      default:
        return { ...defaultSxProps, ...bottomPositionStyles };
    }
  };
  const getDirection = () => {
    switch (scannerLocation) {
      case ScannerLocation.left:
        return 'row-reverse';
      case ScannerLocation.right:
        return 'row';
      case ScannerLocation.top:
        return 'column-reverse';
      default:
        return 'column';
    }
  };
  const getHeight = (): string => {
    switch (scannerIconSize) {
      case ScannerIconSize.large:
        return '25vh';
      case ScannerIconSize.medium:
        return '20vh';
      case ScannerIconSize.small:
        return '15vh';
      default:
        return '15vh';
    }
  };
  return (
    <Box sx={getStyles()}>
      <motion.div
        animate={{ x, y, opacity: 1, scale: 1 }}
        transition={{
          duration: 3,
          delay: 0.3,
          ease: 'linear',
          repeat: Infinity,
          repeatDelay: 1,
        }}
      >
        {embeddedQrScannerType == ScannerType.barcode ? (
          <Box sx={{ transform: rotate }} component={'img'} src={'/images/barcode-scanner-15cm.png'}></Box>
        ) : (
          <Stack
            spacing={2}
            direction={getDirection()}
            justifyContent={'center'}
            alignItems={'center'}
            height={getHeight()}
            sx={{ transform: rotate, align: 'center' }}
          >
            <Icons.QrCodeScanner transform={rotate} />
            <Icons.ScannerArrows transform={rotate} />
          </Stack>
        )}
      </motion.div>
    </Box>
  );
};

export default ScannerIndicator;
