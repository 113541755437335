import { styled, SxProps, Theme, Typography } from '@mui/material';
import { TicketType } from '../../modules/tickets/models';

export const TicketNameTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.14rem',
    lineHeight: '1.7rem',
  },
  [theme.breakpoints.down('xl')]: {
    fontSize: '1.25rem',
    lineHeight: '1.875rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.875rem',
    lineHeight: '2.8125rem',
  },
}));

export const TicketLongNameTypography = styled(TicketNameTypography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '0.9rem',
    lineHeight: '1.2rem',
  },
}));

export const FreeTicketNameTypography = styled(TicketNameTypography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.25rem',
    lineHeight: '1.875rem',
  },
}));

export const TicketQuantity = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: '2rem',
    minWidth: '22px',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.5rem',
    minWidth: '30px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.2rem',
  },
}));

export const iconStyles = (theme) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: '4.5rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '5.1875rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '3.5rem',
  },
});

export const PriceText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontWeight: 300,
    fontSize: '0.9rem',
    alignSelf: 'center',
    justifySelf: 'right',
  },

  [theme.breakpoints.up('sm')]: {
    fontWeight: 300,
    fontSize: '1.3rem',
    alignSelf: 'center',
    justifySelf: 'right',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.78rem',
  },
  whiteSpace: 'nowrap',
}));

export const ProductPriceText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontWeight: 300,
    fontSize: '0.9rem',
  },

  [theme.breakpoints.up('sm')]: {
    fontWeight: 300,
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.78rem',
  },
  whiteSpace: 'nowrap',
}));

export const getTicketBgColor = (type: TicketType) => {
  switch (type) {
    case TicketType.NORMAL:
      return '#6260A8';
    case TicketType.FREE:
      return '#FFB039';
    case TicketType.REDUCED:
      return '#EE4A83';
    case TicketType.PARKING:
      return '#4398D1';
    default:
      return '';
  }
};

export const getTicketProps = (isSmallScreen: boolean): SxProps => {
  if (isSmallScreen) {
    return { maxWidth: '31px', objectFit: 'contain', display: 'inline' };
  }
  return { maxWidth: '54px', objectFit: 'contain', display: 'inline' };
};

export const ButtonsStackStyles = {
  alignContent: 'center',
  justifyContent: 'right',
  '& Button:disabled svg': { color: 'rgba(0, 0, 0, 0.26)' },
} as SxProps<Theme>;

export const displayPrice = (amount: number): string => {
  return amount.toFixed(2);
};

export const HeaderText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
    textAlign: 'center',
    display: 'block',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.42rem',
  },
  color: '#4E4E4E',
}));
