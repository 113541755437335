import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { EloSecondaryButton } from '../../components/BergButtons';
import { Divider, IconButton, List, ListItemText, Stack, Typography } from '@mui/material';
import { StatModel } from '../../modules/tickets/models';
import CloseIcon from '@mui/icons-material/Close';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  width: '100%',
  bgcolor: 'background.paper',
};

interface StatsDialogProps {
  open: boolean;
  onClose: () => void;
  stats: StatModel[];
}

const StatsDialog = (props: StatsDialogProps) => {
  const { t } = useTranslation(['common']);
  const handleClose = () => {
    props.onClose();
  };

  const getListText = (stat: StatModel) => {
    return format(new Date(stat.date), 'dd.MM HH:mm') + ' - ' + stat.availableTickets + ' / 400';
  };

  return (
    <div>
      <Dialog open={props.open} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={{ borderBottom: 'solid #949494 1px' }}>
          <Stack direction="row" spacing={4} alignItems="center">
            <Typography>
              <>{t('common:tickets.statsHeader')}</>
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <List sx={style} component="nav" aria-label="mailbox folders">
            {props.stats.map((s) => (
              <React.Fragment key={s.date}>
                <ListItemText primary={getListText(s)} sx={{ px: 2 }} />
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <EloSecondaryButton onClick={handleClose}>
            <>{t('common:buttons.close')}</>
          </EloSecondaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatsDialog;
