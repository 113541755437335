import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Slice } from '../../types';
import { AccountStateModel, Operator } from './models';
import { loginOperator } from './operations';

const initialState = {
  operatorLoginEnabled: false,
} as AccountStateModel;

const accountSlice = createSlice({
  name: Slice.Basket,
  initialState: initialState,
  reducers: {
    logOut: (state) => {
      state.operator = undefined;
    },
    setOperatorLoginEnabled: (state, action: PayloadAction<boolean>) => {
      state.operatorLoginEnabled = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(loginOperator.fulfilled, (state, action: PayloadAction<Operator>) => {
      console.log('Set operator', JSON.stringify(action.payload));
      state.operator = action.payload;
    });
  },
});

export const { setOperatorLoginEnabled } = accountSlice.actions;
export default accountSlice.reducer;
