import { Box, LinearProgress, linearProgressClasses, Stack, styled, Typography } from '@mui/material';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { getClientLayout } from '../modules/layout/selectors';
import { Layout } from '../setup/Layout';
import { useAppSelector } from '../utils/hooks';

const BatteryProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: 1,
  width: '27px',
  height: '13px',
  marginTop: '3px',
  marginBottom: '3px',
  marginLeft: '3px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 1,
    backgroundColor: '#58585A',
  },
}));

const BatteryLevel = () => {
  const [currentTime, setCurrentTime] = useState<string>();
  const [batteryLevel, setBatteryLevel] = useState<number>(-1);

  const layout = useAppSelector((x) => getClientLayout(x.layout));

  useEffect(() => {
    setTime();
    setInterval(() => {
      setTime();
    }, 1000);
  }, []);

  useEffect(() => {
    if (layout === Layout.Mobile) {
      getBatteryLevel();

      setInterval(() => {
        getBatteryLevel();
      }, 60 * 1000);
    }
  }, [layout]);

  const setTime = () => {
    const utcDate = zonedTimeToUtc(new Date(), 'Europe/Warsaw');
    setCurrentTime(format(utcDate, 'HH:mm'));
  };

  const getBatteryLevel = () => {
    if (window.BtDevice) {
      try {
        const batteryLevel = window.BtDevice.getBatteryLevel();
        setBatteryLevel(batteryLevel);
      } catch (e) {
        console.error(JSON.stringify(e));
        setBatteryLevel(-1);
      }
    } else {
      setBatteryLevel(-1);
    }
  };

  const TimeText = styled(Typography)({
    color: '#58585A',
  });

  return (
    <>
      <Stack direction="row" spacing={2}>
        <TimeText>{currentTime}</TimeText>
        {layout === Layout.Mobile && (
          <Stack direction="row" spacing={1} alignItems="center">
            <TimeText>{batteryLevel >= 0 ? `${batteryLevel}%` : '?'}</TimeText>
            <Box width="34px" height="19px" sx={{ backgroundImage: "url('/images/battery.png')" }}>
              <BatteryProgress value={batteryLevel} variant="determinate"></BatteryProgress>
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default BatteryLevel;
