import { Dialog, DialogActions, DialogContent, DialogTitle, styled, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GreenButton, RedButton } from '../../components/styled-components';
import { TicketDetails } from '../../modules/tickets/models';
import { saveAnswer } from '../../modules/tickets/operations';

const AnswerInput = styled(TextField)({
  width: '100%',
  '& input': {
    fontSize: '2rem',
  },
});

interface AnswerEditorProps {
  handleClose: () => void;
  open: boolean;
  ticket: TicketDetails | null;
}

const AnswerEditor = ({ handleClose, open, ticket }: AnswerEditorProps) => {
  const { t } = useTranslation(['common']);
  const [answer, setAnswer] = useState<string>('');
  useEffect(() => {
    if (ticket?.question?.answer) {
      setAnswer(ticket?.question?.answer);
    }
  }, [ticket?.question?.answer]);

  const handleSave = async () => {
    await saveAnswer(ticket?.ticketId!, answer, ticket?.orderId!, ticket?.question?.questionAnswerId!);
    if (ticket?.question?.answer) {
      ticket.question.answer = answer;
    }
    handleClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ textAlign: 'center' }}>{ticket?.question?.question}</DialogTitle>
      <DialogContent>
        <AnswerInput value={answer} onChange={(e) => setAnswer(e.target.value)}></AnswerInput>
      </DialogContent>
      <DialogActions>
        <RedButton onClick={handleClose}>
          <>{t('common:buttons.cancel')}</>
        </RedButton>
        <GreenButton onClick={handleSave}>
          <>{t('common:buttons.save')}</>
        </GreenButton>
      </DialogActions>
    </Dialog>
  );
};

export default AnswerEditor;
