import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { EloSecondaryButton } from '../../components/BergButtons';
import { FormControl, IconButton, List, ListItem, OutlinedInput, Stack, Typography } from '@mui/material';
import { Ticket, TicketType } from '../../modules/tickets/models';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getBasketTicketsByVariant } from '../../modules/basket/selectors';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { addBasketTicket, removeBasketTicket } from '../../modules/basket/slice';
import { BasketTicket } from '../../modules/basket/models';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  width: '100%',
  bgcolor: 'background.paper',
};

interface TicketDataDialogProps {
  open: boolean;
  onClose: () => void;
  ticket: Ticket;
}

const TicketDataDialog = (props: TicketDataDialogProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const basketTickets = useAppSelector((s) => getBasketTicketsByVariant(s.basket, props.ticket.variantId));
  const [inputText, setInputText] = React.useState<string>('');
  const handleClose = () => {
    props.onClose();
  };

  const handleAdd = (close: boolean) => {
    if (close && inputText.length === 0) {
      props.onClose();
      return;
    }
    if (inputText.length === 0) {
      return;
    }
    dispatch(
      addBasketTicket({
        amount: props.ticket.price.amount,
        quantity: 1,
        question: {
          answer: inputText,
          questionId: props.ticket.question.questionId,
        },
        ticket: props.ticket,
        id: uuidv4(),
      } as BasketTicket)
    );

    setInputText('');

    if (close) {
      props.onClose();
    }
  };

  const handleRemove = (id: string) => {
    dispatch(removeBasketTicket(id));
  };

  const newTicketVisibility = (): boolean => {
    if (props.ticket.type === TicketType.PARKING) {
      if (basketTickets?.length > 0) {
        return false;
      }
    }
    return true;
  };

  return (
    <div>
      <Dialog
        open={props.open}
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ borderBottom: 'solid #949494 1px' }}>
          <Stack direction="row" spacing={4} alignItems="center">
            <Typography>{props.ticket.question.question}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ p: 0 }}>
          <List sx={style} component="nav" aria-label="mailbox folders">
            {basketTickets?.map((b, i) => (
              <ListItem key={b.id}>
                <Typography sx={{ mr: 2 }}>{i + 1} </Typography>
                <FormControl>
                  <OutlinedInput value={b.question.answer} disabled></OutlinedInput>
                </FormControl>
                <IconButton onClick={() => handleRemove(b.id)}>
                  <RemoveCircleOutlineOutlinedIcon></RemoveCircleOutlineOutlinedIcon>
                </IconButton>
              </ListItem>
            ))}
            {newTicketVisibility() && (
              <ListItem>
                <Typography sx={{ mr: 2 }}>{(basketTickets?.length ?? 0) + 1}</Typography>
                <FormControl>
                  <OutlinedInput value={inputText} onChange={(e) => setInputText(e.target.value)}></OutlinedInput>
                </FormControl>
              </ListItem>
            )}

            {props.ticket.type === TicketType.FREE && (
              <ListItem>
                <Typography sx={{ mr: 2 }}>
                  <>{t('common:tickets.addNextKdrNumber')}</>
                </Typography>
                <IconButton onClick={() => handleAdd(false)}>
                  <AddCircleOutlineOutlinedIcon
                    sx={{ color: '#6260A8' }}
                    fontSize="large"
                  ></AddCircleOutlineOutlinedIcon>
                </IconButton>
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions sx={{ p: 3, justifyContent: 'center' }}>
          <EloSecondaryButton onClick={() => handleAdd(true)}>
            <>{t('common:tickets.addButtonText')}</>
          </EloSecondaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TicketDataDialog;
