import { Box, Stack, Typography } from '@mui/material';
import { Layout } from '../../setup/Layout';
import { ScannerLocation } from '../../utils/device';
import { VioletButton } from '../../components/styled-components';
import React from 'react';
import { useAppSelector, useScannerIndicator } from '../../utils/hooks';
import { getClientLayout, getEmbeddedQrScanner, getEmbeddedQrScannerLocation } from '../../modules/layout/selectors';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import ScannerIndicator from '../../components/ScannerIndicator/ScannerIndicator';

const ScanHeader = styled(Typography)({
  fontSize: '1.125rem',
  color: '#2B2A2A',
  lineHeight: '27px',
  fontWeight: 700,
});

const GenericBarcodeScanner = () => {
  const { t } = useTranslation(['common']);
  const scannerIndicator = useScannerIndicator();

  const clientLayout = useAppSelector((s) => getClientLayout(s.layout));
  const scannerLocation = useAppSelector((x) => getEmbeddedQrScannerLocation(x.layout));
  const embeddedQrScanner = useAppSelector((x) => getEmbeddedQrScanner(x.layout));
  const handleScanClicked = () => {
    console.log('Starting CameraQrScanner ');
    window.BtDevice.startCameraQrScanner();
  };

  return (
    <Stack direction={'column'}>
      {embeddedQrScanner ? (
        <Stack
          direction={'column'}
          spacing={2}
          sx={{ height: clientLayout == Layout.Desktop ? '50vh' : '30vh', justifyContent: 'flex-end' }}
        >
          <ScanHeader align="center" sx={{ pt: 3 }}>
            <>{t('common:scanner.useScanner')}</>
          </ScanHeader>
          {clientLayout == Layout.Desktop && scannerIndicator && scannerLocation === ScannerLocation.bottom ? (
            <Box component={'img'} src={'/images/screen.png'} sx={{ height: '30vh', objectFit: 'contain' }} />
          ) : null}
        </Stack>
      ) : (
        <Stack direction="column" spacing={3} alignItems="center">
          <ScanHeader>
            <>{t('common:scanner.scanQr')}</>
          </ScanHeader>
          <Stack sx={{ backgroundColor: '#999999', width: '100%', height: '40vh', justifyContent: 'center' }}>
            <Box component="img" src="/images/camera.png" alignSelf="center" justifySelf="center"></Box>
          </Stack>
          <VioletButton onClick={handleScanClicked}>
            <>{t('common:scanner.scan')}</>
          </VioletButton>
        </Stack>
      )}
      {scannerIndicator ? <ScannerIndicator /> : null}
    </Stack>
  );
};

export default GenericBarcodeScanner;
