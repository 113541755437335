import React from 'react';
import ProductBox from '../../../components/TicketButton/Tiles/ProductBox';
import { useAppSelector } from '../../../utils/hooks';
import { getProducts } from '../../../modules/products/selectors';
import { Box } from '@mui/material';

const ProductList = () => {
  const products = useAppSelector((s) => getProducts(s.product));
  return (
    <Box sx={{ paddingBottom: '64px' }}>
      {products?.map((product) => {
        return product.variants.map((variant) => {
          return (
            <Box sx={{ mb: 1 }} key={product.id + variant.id}>
              <ProductBox product={product} variant={variant}></ProductBox>
            </Box>
          );
        });
      })}
    </Box>
  );
};

export default ProductList;
