import { BasketProduct, BasketTicket } from '../basket/models';
import { ConfirmPaymentRequest, OrderStatus, PaymentType, ProductOrder, RequestTicket } from './models';

export const MapToRequestTicket = (basketTickets: BasketTicket[]): RequestTicket[] => {
  return basketTickets.map((b) => {
    return {
      quantity: b.quantity,
      variantId: b.ticket.variantId,
      question: b.question,
    } as RequestTicket;
  });
};

export const CreateConfirmPaymentRequestModel = (
  paymentStatus: OrderStatus,
  paymentType: PaymentType,
  amount: number,
  transactionId: string | null
): ConfirmPaymentRequest => {
  return {
    orderId: paymentStatus.orderId,
    paymentType: paymentType,
    amount: amount,
    transactionId: transactionId,
  } as ConfirmPaymentRequest;
};

export const MapToProductOrder = (basketProducts: BasketProduct[]): ProductOrder[] => {
  return basketProducts.map((p) => {
    return {
      productId: p.product.id,
      quantity: p.quantity,
      variantId: p.variantId,
    } as ProductOrder;
  });
};
