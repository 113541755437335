import { Button, Stack, Typography } from '@mui/material';
import { getBasketTicketSummary } from '../../../modules/basket/selectors';
import { Ticket, TicketType } from '../../../modules/tickets/models';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { decrement, increment } from '../../../modules/basket/slice';
import React, { useState } from 'react';
import TicketDataDialog from '../../../pages/tickets/TicketDataDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { iconStyles, TicketQuantity } from '../styles';
import PriceTicketTileWrapper from './PriceTicketTileWrapper';
import { toWithReturnParam } from '../../../utils/navigationHelper';

const showTicketQuantityRangeMessage = (min: number | null, max: number | null): string => {
  if (max == null) {
    if (min != null && min > 1) {
      return `Minimalna ilość: ${min}`;
    }
  } else {
    if (min != null && min > 1) {
      return `${min} - ${max}`;
    }
    return `Maksymalna ilość: ${max}`;
  }

  return '';
};

interface PriceTicketBoxProps {
  ticket: Ticket;
}

const PriceTicketTile = ({ ticket }: PriceTicketBoxProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [openTicketDataDialog, setOpenTicketDataDialog] = useState<boolean>(false);
  const basketTicketSummary = useAppSelector((state) =>
    getBasketTicketSummary(state.basket, undefined, ticket.variantId)
  );
  const basketSummary = useAppSelector((state) => getBasketTicketSummary(state.basket));

  const reachedMax = (): boolean => {
    return (
      ticket.available <= (basketSummary?.quantity ?? 0) ||
      (ticket.maxQuantity != null && basketTicketSummary.quantity >= ticket.maxQuantity)
    );
  };

  const handleDecrementClick = () => {
    if (ticket.question !== null) {
      setOpenTicketDataDialog(true);
      return;
    } else {
      dispatch(decrement(ticket));
    }
  };

  const handleIncrementClick = () => {
    if (ticket.question !== null) {
      console.log('Incrementing ticket: ' + JSON.stringify(ticket));
      if (ticket.type === TicketType.PARKING) {
        navigate(toWithReturnParam(location, `/vehicle?variantId=${ticket.variantId}`));
      } else {
        setOpenTicketDataDialog(true);
      }

      return;
    } else {
      dispatch(increment(ticket));
    }
  };

  return (
    <>
      <PriceTicketTileWrapper ticket={ticket}>
        <Stack direction="column" alignItems={'center'}>
          <Stack direction={'row'} spacing={{ xs: 2, md: 0, lg: 2 }}>
            <Button size="small" onClick={handleDecrementClick} disabled={basketTicketSummary?.quantity === 0}>
              <RemoveCircleOutlineIcon sx={iconStyles} htmlColor="white" />
            </Button>
            <TicketQuantity sx={{ alignSelf: 'center' }} align="center">
              {basketTicketSummary?.quantity ?? 0}
            </TicketQuantity>
            <Button disabled={reachedMax()} onClick={handleIncrementClick}>
              <AddCircleOutlineIcon sx={iconStyles} htmlColor="white" />
            </Button>
          </Stack>
          {ticket.minQuantity != null || ticket.maxQuantity != null ? (
            <Typography>{showTicketQuantityRangeMessage(ticket.minQuantity, ticket.maxQuantity)}</Typography>
          ) : null}
        </Stack>
      </PriceTicketTileWrapper>
      {/*TODO: create one dialog, fill and open with store, crete hook for it  */}
      {ticket?.question !== null ? (
        <TicketDataDialog
          open={openTicketDataDialog}
          onClose={() => setOpenTicketDataDialog(false)}
          ticket={ticket}
        ></TicketDataDialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default PriceTicketTile;
