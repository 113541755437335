export interface ProductStateModel {
  categories: Category[];
  products: Product[];
  allProducts: Product[];
  product: Product | null;
  selectedCategoryId?: string;
  filterText?: string;
  openBottomDialogSheet: boolean;
}
export interface Category {
  id: string;
  code: string;
}

export enum CategoryCode {
  books = 'books',
  cloths = 'cloths',
  gadgets = 'gadgets',
  magnets = 'magnets',
  maps = 'maps',
  mascots = 'mascots',
  undefined = 'undefined',
}

export interface Product {
  id: string;
  name: string;
  variants: ProductVariant[];
  categories: Category[];
}

export interface ProductVariant {
  id: string;
  name: string;
  price: ProductPrice;
  vatPercentage: number;
}

export interface ProductPrice {
  amount: number;
  currency: string;
}

export interface ProductsRequestQuery {
  categoryId: string;
  search: string;
}
