import React from 'react';
import { useAppSelector } from '../../utils/hooks';
import { getSelfCheckout } from '../../modules/layout/selectors';
import { useTranslation } from 'react-i18next';
import Item from './Item';
import OutlinedItem from './OutlinedItem';

const TicketSellCheckoutItem = () => {
  const { t } = useTranslation('common');
  const selfCheckout = useAppSelector((s) => getSelfCheckout(s.layout));

  return selfCheckout ? (
    <OutlinedItem
      sx={{ maxHeight: '20vmin' }}
      fontColor="#6260A8"
      iconUrl="/images/sel-ticket.png"
      description={t('modeSelector.ticketBuy')}
      pageUrl="/tickets"
    ></OutlinedItem>
  ) : (
    <Item
      sx={{ backgroundColor: '#6260A8', '&:hover': { backgroundColor: '#6260A8' } }}
      description={t('modeSelector.ticketSale')}
      iconUrl="/images/sel-ticket.png"
      pageUrl="/tickets"
    ></Item>
  );
};

export default TicketSellCheckoutItem;
