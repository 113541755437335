import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import i18next from 'i18next';

const steps = [
  i18next.t('common:payments.paymentTypeStep'),
  i18next.t('common:payments.paymentStep'),
  i18next.t('common:payments.paymentConfirmationStep'),
];

interface PaymentStepperProps {
  activeStep: number;
}

const PaymentStepper = ({ activeStep }: PaymentStepperProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, i) => (
          <Step key={i}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default PaymentStepper;
