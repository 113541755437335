import { combineReducers } from '@reduxjs/toolkit';
import ticketSlice from '../modules/tickets/slice';
import basketSlice from '../modules/basket/slice';
import paymentSlice from '../modules/payment/slice';
import layoutSlice from '../modules/layout/slice';
import accountSlice from '../modules/account/slice';
import productSlice from '../modules/products/slice';
import parkingTicketsSlice from '../modules/parkingTickets/slice';

const rootReducer = combineReducers({
  tickets: ticketSlice,
  basket: basketSlice,
  payment: paymentSlice,
  layout: layoutSlice,
  account: accountSlice,
  product: productSlice,
  parkingTickets: parkingTicketsSlice,
});

export default rootReducer;
