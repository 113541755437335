import Search from './company-details/Search';

const CompanyDetails = () => {
  return (
    <>
      <Search></Search>
    </>
  );
};

export default CompanyDetails;
