import { InvoiceData, TicketType } from '../tickets/models';
import { BasketProduct, BasketStateModel, BasketTicket, BasketSummary } from './models';
import { ProductVariant } from '../products/models';

export const getBasketTicket = (state: BasketStateModel, variantId: number) => {
  return state.basketTickets.find((x) => x.ticket.variantId === variantId);
};

export const getBasketTicketsByVariant = (state: BasketStateModel, variantId: number): BasketTicket[] => {
  return state.basketTickets.filter((x) => x.ticket.variantId === variantId);
};

export const getBasketTickets = (state: BasketStateModel) => {
  return state.basketTickets;
};

export const getBasketAmount = (state: BasketStateModel): number => {
  return state.basketTickets.map((t) => t.amount).reduce((sum, x) => sum + x, 0);
};

export const getBasketTicketCount = (state: BasketStateModel): number => {
  return state.basketTickets.map((t) => t.quantity).reduce((sum, x) => sum + x, 0);
};

export const getBasketTicketSummary = (
  state: BasketStateModel,
  ticketType?: TicketType,
  variantId?: number
): BasketSummary => {
  let tickets = [] as BasketTicket[];

  if (ticketType !== undefined) {
    tickets = state.basketTickets.filter((x) => x.ticket.type === ticketType);
  } else if (variantId !== undefined) {
    tickets = state.basketTickets.filter((x) => x.ticket.variantId === variantId);
  } else {
    tickets = state.basketTickets;
  }
  return {
    amount: tickets.map((t) => t.amount).reduce((sum, x) => sum + x, 0),
    quantity: tickets.map((t) => t.quantity).reduce((sum, x) => sum + x, 0),
    currency: tickets.length > 0 ? tickets[0]?.ticket?.price?.currency : 'PLN',
  } as BasketSummary;
};

export const getBasketProductSummary = (
  state: BasketStateModel,
  productVariant: ProductVariant
): BasketProduct | undefined => {
  return state.basketProducts.find((p) => p.variantId === productVariant.id);
};

export const getBasketSummary = (state: BasketStateModel): BasketSummary => {
  const ticketsAmount = state.basketTickets?.map((t) => t.amount).reduce((sum, x) => sum + x, 0);
  const ticketCount = state.basketTickets?.map((t) => t.quantity).reduce((sum, x) => sum + x, 0);
  const ticketCurrency = state.basketTickets?.length > 0 ? state.basketTickets[0]?.ticket?.price?.currency : undefined;

  const productsAmount = state.basketProducts?.map((t) => t.amount).reduce((sum, x) => sum + x, 0);
  const productsCount = state.basketProducts?.map((t) => t.quantity).reduce((sum, x) => sum + x, 0);
  const productsCurrency =
    state.basketProducts?.length > 0 && state.basketProducts[0].product.variants.length > 0
      ? state.basketProducts[0]?.product.variants[0].price.currency
      : undefined;

  return {
    amount: ticketsAmount + productsAmount,
    quantity: ticketCount + productsCount,
    currency: ticketCurrency ?? productsCurrency ?? 'PLN',
  };
};

export const getOtherFreeTicketsBasketSummary = (state: BasketStateModel) => {
  let tickets = state.basketTickets.filter(
    (x) => x.ticket.type === TicketType.FREE && x.ticket.variantId !== 146444 && x.ticket.variantId !== 146445
  );
  return {
    amount: tickets.map((t) => t.amount).reduce((sum, x) => sum + x, 0),
    quantity: tickets.map((t) => t.quantity).reduce((sum, x) => sum + x, 0),
    currency: tickets.length > 0 ? tickets[0].ticket.price.currency : 'PLN',
  } as BasketSummary;
};

export const getInvoiceData = (state: BasketStateModel): InvoiceData | undefined => {
  return state.invoiceData;
};

export const getBasketProductsCount = (state: BasketStateModel): number => {
  return state.basketProducts.reduce((sum, product) => sum + product.quantity, 0);
};

export const getBasketProductsAmount = (state: BasketStateModel): number => {
  return state.basketProducts.reduce((sum, product) => sum + product.amount, 0);
};

export const getBasketProducts = (state: BasketStateModel): BasketProduct[] => {
  return state.basketProducts;
};
