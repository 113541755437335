import { Button } from '@mui/material';
import { getBasketProductSummary, getBasketTicketSummary } from '../../../modules/basket/selectors';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { decrementProduct, incrementProduct } from '../../../modules/basket/slice';
import { iconStyles, TicketQuantity } from '../styles';
import { Product, ProductVariant } from '../../../modules/products/models';
import ProductBoxWrapper from './ProductBoxWrapper';

type ProductBoxProps = {
  variant: ProductVariant;
  product: Product;
};

const ProductBox = ({ product, variant }: ProductBoxProps) => {
  const basketProductSummary = useAppSelector((state) => getBasketProductSummary(state.basket, variant));
  const dispatch = useAppDispatch();

  const handleDecrementClick = () => {
    dispatch(decrementProduct(variant));
  };

  const handleIncrementClick = () => {
    console.log('Incrementing product: ' + JSON.stringify(variant));

    dispatch(incrementProduct([variant, product]));
  };

  return (
    <>
      <ProductBoxWrapper productVariant={variant}>
        <>
          <Button size="small" onClick={handleDecrementClick} disabled={basketProductSummary?.quantity === 0}>
            <RemoveCircleOutlineIcon sx={iconStyles} htmlColor="white" />
          </Button>
          <TicketQuantity sx={{ alignSelf: 'center' }} align="center">
            {basketProductSummary?.quantity ?? 0}
          </TicketQuantity>
          <Button onClick={handleIncrementClick}>
            <AddCircleOutlineIcon sx={iconStyles} htmlColor="white" />
          </Button>
        </>
      </ProductBoxWrapper>
    </>
  );
};

export default ProductBox;
