export enum TicketColor {
  Normal = '#6260A8',
  Reduced = '#EE4A83',
  Free = '#FFB039',
  Parking = '#4398D1',
  Product = '#6260A8 ',
}

export enum ButtonColor {
  Violet = '#9464FC',
  VioletHover = '#7650c9',
  Grey = '#5E5E5E',
  GreyHover = '#7e7e7e',
  Blue = '#4398D1',
  BlueHover = '#3579a7',
  Red = '#FF4242',
  RedHover = '#ff6767',
  Green = '#32BA7C',
  GreenHover = '#5BC796',
}

export enum PayboxColors {
  Light3 = '#DFDFE6',
  Light = '#FCFCFD',
}
