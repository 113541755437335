import { Location } from 'react-router-dom';

export const toWithReturnParam = (location: Location, to: string): string => {
  let url = to;
  if (to.indexOf('?') > -1) {
    url += '&';
  } else {
    url += '?';
  }
  url += `r=${location.pathname}`;
  if (location.search) {
    if (location.search.substring(0, 3) === '?r=') {
      url += `${location.search.replace('?', '&').replace(/&(?!r)/g, '**')}`;
    } else {
      url += `${location.search.replace('?', '**').replace(/&(?!r)/g, '**')}`;
    }
  }
  return url;
};

export const getReturnUrl = (searchParams: URLSearchParams, defaultUrl: string): string => {
  const rParam = searchParams.get('r');
  console.log('rParam', rParam);
  if (rParam) {
    let params = '';
    if (rParam.indexOf('**') > -1) {
      params += '&';
    } else {
      params += '?';
    }
    if (searchParams.getAll('r').length > 1) {
      params += `r=${searchParams.getAll('r')[1]}`;
    }
    if (searchParams.getAll('r').length > 2) {
      params += `&r=${searchParams.getAll('r')[2]}`;
    }
    if (searchParams.getAll('r').length > 3) {
      params += `&r=${searchParams.getAll('r')[3]}`;
    }

    if (params.length <= 1) {
      console.log('getReturnUrl length ' + params.length);
      console.log('rParam replace firs **', rParam.replace('**', '?'));
      return rParam.replace('**', '?').replace(/\*\*./g, '&');
    }
    console.log('getReturnUrl > 0');
    console.log('rParam', rParam);
    console.log('params', params);
    return rParam.replace('**', '?').replace(/\*\*./g, '&') + params;
  }

  return defaultUrl;
};
