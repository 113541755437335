export const namespaces = {
  pages: 'pages',
  components: 'components',
  common: 'common',
};

export const languages = {
  en: 'en',
  pl: 'pl',
  ua: 'ua',
};
