import { Grid, LinearProgress, Box, Typography, styled, Stack } from '@mui/material';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import { StatModel } from '../modules/tickets/models';

const StatText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.down('lg')]: {
    fontSize: '0.8rem',
  },
  color: '#1A202C',
}));

const StatFrame = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
    fontSize: '0.8rem',
  },
  border: '1px solid #E1E1E1',
  borderRadius: '0.9rem',
  padding: theme.spacing(2),
  boxShadow: 'none',
}));

interface StatBoxProps {
  statsModel: StatModel;
  isFirst: boolean;
  onClick: () => void;
}

const StatBox = (props: StatBoxProps) => {
  const { t } = useTranslation('common');
  const getProgress = () => {
    return (props.statsModel.availableTickets / props.statsModel.originalAvailability) * 100;
  };

  return (
    <>
      <StatFrame onClick={() => props.onClick()}>
        <Stack direction="column" spacing={2}>
          {props.isFirst ? (
            <StatText>
              {format(new Date(props.statsModel.date), 'dd.MM.yyyy')} - {t('tickets.statsSold').toString()} {props.statsModel.originalAvailability - props.statsModel.availableTickets}/{props.statsModel.originalAvailability} {t('tickets.originalAvailabilityTicketsText').toString()}
            </StatText>
          ) : (
            <StatText>
              {format(new Date(props.statsModel.date), 'HH:mm')} - {t('tickets.statsLeft').toString()} {props.statsModel.availableTickets} {t('tickets.originalAvailabilityTicketsText').toString()}
            </StatText>
          )}
          <LinearProgress value={getProgress()} variant="determinate" sx={{ borderRadius: 50, height: 6 }}></LinearProgress>
        </Stack>
        <Grid container>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sx={{ mt: 1 }}></Grid>
        </Grid>
      </StatFrame>
    </>
  );
};

export default StatBox;
