import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PlateForm from '../components/PlateForm';

const PlateSearcher = () => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const handleConfirmClick = (plate: string) => {
    navigate(`/plate_search/result?search=${plate}`);
  };
  return (
    <PlateForm
      handleReturn={() => navigate('/')}
      handleConfirm={handleConfirmClick}
      confirmButtonText={t('common:buttons.search')}
      bgColor={'#FFB039'}
    ></PlateForm>
  );
};

export default PlateSearcher;
