import { Stack, Typography } from '@mui/material';
import { TicketType } from '../../modules/tickets/models';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useTranslation } from 'react-i18next';

const getFontColor = (ticketType: TicketType) => {
  switch (ticketType) {
    case TicketType.NORMAL:
      return '#6260A8';
    case TicketType.FREE:
      return '#790000';
    case TicketType.REDUCED:
      return '#454396';
    default:
      return '';
  }
};

const getBackgroundColor = (ticketType: TicketType) => {
  switch (ticketType) {
    case TicketType.NORMAL:
      return '#FBDD6B';
    case TicketType.FREE:
      return '#FF5858';
    case TicketType.REDUCED:
      return '#6BE1FB';
    default:
      return '';
  }
};
interface UnavailableBoxProps {
  ticketType: TicketType;
}
const UnavailableBox = (props: UnavailableBoxProps) => {
  const { t } = useTranslation(['common']);
  return (
    <Stack direction="row" sx={{ py: 1, px: 3, alignItems: 'center', justifyContent: 'center', backgroundColor: getBackgroundColor(props.ticketType) }}>
      <PriorityHighIcon sx={{ color: getFontColor(props.ticketType), borderColor: getFontColor(props.ticketType), border: '4px solid', borderRadius: '4px', width: 33, height: 33 }}></PriorityHighIcon>
      <Typography sx={{ color: getFontColor(props.ticketType), ml: 2 }}>{t('common:tickets.noTickets').toString()}</Typography>
    </Stack>
  );
};

export default UnavailableBox;
