import { Collapse, List } from '@mui/material';
import { TicketColor } from '../../layout/colors';
import { TicketType } from '../../modules/tickets/models';
import { getBasketProducts, getBasketTicketSummary } from '../../modules/basket/selectors';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import TicketListItem from './TicketListItem';
import { deleteTicketGroup, removeProduct } from '../../modules/basket/slice';
import { TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { BasketProduct } from '../../modules/basket/models';

const TicketList = () => {
  const { t } = useTranslation('common');

  const normalSummary = useAppSelector((state) => getBasketTicketSummary(state.basket, TicketType.NORMAL));
  const parkingSummary = useAppSelector((state) => getBasketTicketSummary(state.basket, TicketType.PARKING));
  const reducedSummary = useAppSelector((state) => getBasketTicketSummary(state.basket, TicketType.REDUCED));
  const freeSummary = useAppSelector((state) => getBasketTicketSummary(state.basket, TicketType.FREE));
  const products = useAppSelector((s) => getBasketProducts(s.basket));
  const dispatch = useAppDispatch();
  const handleDelete = (ticketType: TicketType) => {
    dispatch(deleteTicketGroup(ticketType));
  };

  const handleDeleteProduct = (product: BasketProduct) => {
    dispatch(removeProduct(product));
  };

  return (
    <List sx={{ width: '100%' }}>
      <TransitionGroup>
        <Collapse key={TicketType.NORMAL}>
          <TicketListItem
            ticketSummary={normalSummary}
            name={t('ticketType.normal')}
            color={TicketColor.Normal}
            onDelete={(ticketType) => handleDelete(ticketType)}
            ticketType={TicketType.NORMAL}
          />
        </Collapse>
        <Collapse key={TicketType.REDUCED}>
          <TicketListItem
            ticketSummary={reducedSummary}
            name={t('ticketType.reduced')}
            color={TicketColor.Reduced}
            onDelete={(ticketType) => handleDelete(ticketType)}
            ticketType={TicketType.REDUCED}
          />
        </Collapse>
        <Collapse key={TicketType.PARKING}>
          <TicketListItem
            ticketSummary={parkingSummary}
            name={t('ticketType.parking')}
            color={TicketColor.Normal}
            onDelete={(ticketType) => handleDelete(ticketType)}
            ticketType={TicketType.PARKING}
          />
        </Collapse>
        <Collapse key={TicketType.FREE}>
          <TicketListItem
            ticketSummary={freeSummary}
            name={t('ticketType.free')}
            color={TicketColor.Free}
            onDelete={(ticketType) => handleDelete(ticketType)}
            ticketType={TicketType.FREE}
          />
        </Collapse>
        {products?.map((p) => (
          <Collapse key={p.variantId + p.variantId}>
            <TicketListItem
              ticketSummary={{ amount: p.amount, quantity: p.quantity, currency: p.product.variants[0].price.currency }}
              name={p.product.name}
              color={TicketColor.Product}
              onDelete={() => handleDeleteProduct(p)}
              ticketType={TicketType.PRODUCT}
            />
          </Collapse>
        ))}
      </TransitionGroup>
    </List>
  );
};

export default TicketList;
