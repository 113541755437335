import { Box, Button, Stack, SwipeableDrawer, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { styled } from '@mui/material/styles';

import React from 'react';
import { blue } from '@mui/material/colors';
import { Global } from '@emotion/react';
import { getBasketProductsAmount, getBasketProductsCount } from '../../../modules/basket/selectors';
import { useNavigate } from 'react-router-dom';
import SummaryBox from './SummaryBox';
import { useTranslation } from 'react-i18next';

const drawerBleeding = 54;
const pullerHeight = 6;

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: pullerHeight,
  backgroundColor: theme.palette.mode === 'light' ? blue[300] : blue[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

const SummaryButton = styled(Button)(({ theme }) => ({
  borderRadius: '50px',
  color: 'white',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  height: '40px',
  width: '200px',
  fontSize: '1.1rem',
  backgroundColor: '#9464FC',
  '&:hover': {
    backgroundColor: '#7650c9',
  },
}));

const ProductBottomSheetDialog = () => {
  const { t } = useTranslation('pages');
  const navigate = useNavigate();
  const basketProductsCount = useAppSelector((s) => getBasketProductsCount(s.basket));
  const basketProductsAmount = useAppSelector((s) => getBasketProductsAmount(s.basket));
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = (newOpen?: boolean) => () => {
    if (newOpen === undefined) {
      setOpenDrawer(() => !openDrawer);
    } else {
      setOpenDrawer(newOpen);
    }
  };

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(50% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            /*pointerEvents: 'all',*/
            position: 'absolute',
            top: -drawerBleeding,
            height: drawerBleeding + pullerHeight,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ px: 2, marginTop: `${pullerHeight}px` }}
          >
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 0, md: 2 }}>
              <Typography>
                {t('products.bottomsSheetDialog.productCount')}: <strong>{basketProductsCount}</strong>
              </Typography>
              <Typography>
                {t('products.bottomsSheetDialog.sum')}: <strong>{basketProductsAmount} PLN</strong>
              </Typography>
            </Stack>
            <SummaryButton onClick={() => navigate('/summary?r=/products')} sx={{ pointerEvents: 'all' }}>
              {t('products.bottomsSheetDialog.summary')}
            </SummaryButton>
          </Stack>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <SummaryBox />
        </StyledBox>
      </SwipeableDrawer>
    </>
  );
};

export default ProductBottomSheetDialog;
