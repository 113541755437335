import { IconButton, InputBase, Paper } from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
interface SearchBarProps {
  onTextChange: (text: string) => void;
}
const SearchBar = ({ onTextChange }: SearchBarProps) => {
  const [text, setText] = useState<string>('');
  const handleInputChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onTextChange(e.target.value);
    setText(e.target.value);
  };
  const handleTextClear = () => {
    setText('');
    onTextChange('');
  };
  return (
    <Paper component="form" sx={{ p: '2 4', display: 'flex', alignItems: 'center', width: 1 / 1 }}>
      <IconButton sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Szukaj kodu lub nazwy produktu" value={text} inputProps={{ 'aria-label': 'szukaj kodu lub nazwy produktu' }} onChange={(e) => handleInputChange(e)} />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleTextClear}>
        <ClearIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
