import { Box, Button, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getOtherFreeTicketsBasketSummary } from '../../../modules/basket/selectors';
import { useAppSelector } from '../../../utils/hooks';
import { TicketQuantity, TicketNameTypography, displayPrice, ButtonsStackStyles, PriceText } from '../styles';

type OtherTicketsButtonProps = {
  selectClick: () => void;
};

const OtherTicketsTile = (props: OtherTicketsButtonProps) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const otherFreeTicketsSummary = useAppSelector((state) => getOtherFreeTicketsBasketSummary(state.basket));

  return (
    <>
      <Paper sx={{ backgroundColor: '#FFB039', color: 'white', p: 2, margin: 0 }} elevation={3}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <Stack direction={isMdScreen ? 'row' : 'column'} spacing={1} justifyContent="space-between">
              <Stack
                direction="row"
                sx={{ borderBottom: isMdScreen ? 0 : 1, display: 'inline-flex', justifyContent: 'left', pb: 1 }}
              >
                <Box
                  src="/images/ticket_free.png"
                  component="img"
                  sx={{ width: '54px', height: '37px', display: 'inline' }}
                ></Box>
                <TicketNameTypography>{t('common:tickets.otherFreeTickets')}</TicketNameTypography>
              </Stack>
              <PriceText>
                {displayPrice(otherFreeTicketsSummary.amount)} {otherFreeTicketsSummary.currency}
              </PriceText>
            </Stack>
          </Grid>
          <Grid container item xs={12} md={5} sx={{ alignContent: 'center' }}>
            <Grid item container direction="column" alignContent={isMdScreen ? 'center' : 'flex-end'}>
              <Stack direction="row" sx={ButtonsStackStyles} spacing={1}>
                <Button
                  size="small"
                  onClick={() => props.selectClick()}
                  sx={{
                    background: '#FF5858',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    borderRadius: '50px',
                    px: 4,
                    '&:hover': {
                      backgroundColor: '#cc4646',
                    },
                  }}
                >
                  <Typography sx={{ fontFamily: 'Poppins', fontWeight: 700, fontSize: '1.7rem', color: 'white' }}>
                    {t('common:tickets.selectButton')}
                  </Typography>
                </Button>
                <TicketQuantity sx={{ alignSelf: 'center' }} align="center">
                  {otherFreeTicketsSummary.quantity}
                </TicketQuantity>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default OtherTicketsTile;
