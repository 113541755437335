import { Divider, InputProps, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useRef } from 'react';
import { useAppDispatch } from '../../utils/hooks';
import { loginOperator } from '../../modules/account/operations';
import { VioletButton } from '../styled-components';

const LoginForm = () => {
  const { t } = useTranslation('components');
  const authCodeInputRef = useRef<InputProps>(null);
  const dispatch = useAppDispatch();
  const handleLogin = () => {
    if (authCodeInputRef.current != null) {
      dispatch(loginOperator(authCodeInputRef.current.value as string));
    }
  };
  return (
    <>
      <Stack direction={'column'}>
        <Typography>{t('loginForm.header')}</Typography>
        <Divider />
        <Typography>{t('loginForm.yourCode')}</Typography>
        <TextField aria-placeholder={t('loginForm.yourCodePlaceholder')} inputRef={authCodeInputRef}></TextField>
        <VioletButton onClick={handleLogin}>{t('loginForm.buttonLabel')}</VioletButton>
      </Stack>
    </>
  );
};

export default LoginForm;
