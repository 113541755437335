import { Box, Grid, useTheme, useMediaQuery, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFreeTickets, getOtherFreeTickets } from '../../modules/tickets/selectors';
import { useAppSelector } from '../../utils/hooks';
import TicketList from './TicketList';
import PageHeader from '../../components/PageHeader/PageHeader';
import BottomNavigationWrapper from './BottomNavigationWrapper';

const FreeTickets = () => {
  const { t } = useTranslation(['common']);
  const otherFreeTickets = useAppSelector((s) => getOtherFreeTickets(s.tickets));
  const freeTickets = useAppSelector((s) => getFreeTickets(s.tickets));
  const theme = useTheme();
  const isMDScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ py: 2 }}>
      <PageHeader title={t('common:tickets.freeTicketHeader')} />
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <TicketList tickets={isMDScreen ? otherFreeTickets : freeTickets /* TODO: useMemo */}></TicketList>
      </Grid>
      <BottomNavigationWrapper returnUrl="/tickets" />
    </Box>
  );
};
export default FreeTickets;
