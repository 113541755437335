import { Alert, CircularProgress, Snackbar, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReturnButton } from '../components/styled-components';
import useDisplayToolbarReturnButton from '../layout/layoutHooks';
import { getClientLayout, getEmbeddedQrScanner, getEmbeddedQrScannerType } from '../modules/layout/selectors';
import { useAppSelector } from '../utils/hooks';
import useQrReader from '../hooks/useQrReader';
import GenericBarcodeScanner from './barcodeScanner/GenericBarcodeScanner';
import BarcodeDownPaymentMachine from './barcodeScanner/BarcodeDownPaymentMachine';
import { Layout } from '../setup/Layout';
import { ScannerType } from '../utils/device';

const BarcodeScanner = () => {
  const { t } = useTranslation(['common']);

  const navigate = useNavigate();
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();
  const embeddedQrScanner = useAppSelector((x) => getEmbeddedQrScanner(x.layout));
  const embeddedQrScannerType = useAppSelector((x) => getEmbeddedQrScannerType(x.layout));

  const clientLayout = useAppSelector((x) => getClientLayout(x.layout));

  const [handleQrReader, openLoader, errorMessage, openError, handleOpenError] = useQrReader();

  useEffect(() => {
    if (window.BtApp) {
      if (!embeddedQrScanner) {
        console.log('Starting CameraQrScanner ');
        window.BtDevice.startCameraQrScanner();
      }
      console.log('assign handleQrReader');
      window.BtApp.handleQrReader = handleQrReader;
    }
    return () => {
      if (window.BtApp && window.BtApp.handleQrReader) {
        console.log('Unregister handleQrReader func');
        window.BtApp.handleQrReader = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embeddedQrScanner]);

  return (
    <>
      <Stack direction="column" spacing={2} sx={{ py: 2 }} height={'100%'}>
        {!displayToolbarReturnButton && (
          <ReturnButton onClick={() => navigate('/')}>
            <>{t('common:buttons.back')}</>
          </ReturnButton>
        )}

        {clientLayout == Layout.Desktop && embeddedQrScannerType == ScannerType.barcode ? (
          <BarcodeDownPaymentMachine></BarcodeDownPaymentMachine>
        ) : (
          <GenericBarcodeScanner />
        )}

        {openLoader ? (
          <Stack alignItems="center" direction="column" spacing={1}>
            <CircularProgress />
            <Typography align="center">
              <>{t('common:scanner.fetchingData')}</>
            </Typography>
          </Stack>
        ) : null}
      </Stack>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openError}
        onClose={() => handleOpenError(false)}
        autoHideDuration={3000}
      >
        <Alert onClose={() => handleOpenError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BarcodeScanner;
