import { motion, Variants } from 'framer-motion';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../utils/hooks';
import { getDisableAppAnimations } from '../../modules/layout/selectors';
import { useMemo } from 'react';

const animationVariants: Variants = {
  initial: {
    opacity: 0,
    height: '100%',
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};
const defaultVariants: Variants = {
  initial: {
    height: '100%',
  },
};
const PageTransitionWrapper = () => {
  const disableAppAnimations = useAppSelector((s) => getDisableAppAnimations(s.layout));
  const variants = useMemo(() => (disableAppAnimations ? defaultVariants : animationVariants), [disableAppAnimations]);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={variants}
      transition={{
        type: 'spring',
        damping: 10,
        stiffness: 50,
      }}
    >
      <Outlet />
    </motion.div>
  );
};

export default PageTransitionWrapper;
