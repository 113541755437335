import React from 'react';
import { useAppSelector } from '../../utils/hooks';
import { getRequireLogin } from '../../modules/account/selectors';
import Login from '../../pages/login/Login';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const requireLogin = useAppSelector((x) => getRequireLogin(x.account));
  return <>{requireLogin ? <Login /> : children}</>;
};

export default AuthProvider;
