export const terminalCodeDescriptions = new Map<number, string>([
  [2, 'Transakcja Anulowana'],
  [33, 'Błąd komunikacji z kartą'],
  [20, 'Oczekiwanie na dane karty płatniczej'],
  [30, 'Sprawdzanie zgodności numeru karty'],
  [40, 'Oczekiwanie na identyfikator sprzedawcy'],
  [50, 'Weryfikacja hasła Szefa'],
  [60, 'Oczekiwanie na wprowadzenie kwoty transakcji'],
  [65, 'Terminal jest zajęty'],
  [70, 'Oczekiwanie na poprzednią kwotę transakcji'],
  [80, 'Oczekiwanie na poprzedni kod autoryzacji'],
  [90, 'Oczekiwanie na wprowadzenie kodu PIN'],
  [100, 'Łączenie z hostem autoryzacyjnym'],
  [101, 'Łączenie z hostem autoryzacyjnym - próba 2'],
  [102, 'Łączenie z hostem autoryzacyjnym - próba 3'],
  [110, 'Autoryzacja głosowa'],
  [120, 'Sprawdzanie podpisu klienta'],
  [130, 'Sprawdzanie tożsamości klienta'],
  [140, 'Drukowanie potwierdzenia (pozytywna autoryzacja)'],
  [155, 'Drukowanie kopii potwierdzenia'],
  [150, 'Drukowanie potwierdzenia (odmowa lub błąd)'],
  [180, 'Unieważnienie bieżącej transakcji'],
  [190, 'Drukowanie potwierdzenia unieważnienia'],
  [1000, 'Pytanie o kopię dla klienta'],
  // PayTel LightPOS codes.
  [2001, 'Terminal nie został aktywowany'],
  [2002, 'Terminal nie został odnaleziony'],
  [2003, 'Transakcja zakończona niepowodzeniem'],
  [2004, 'Podano niepoprawny CallBack'],
  [2005, 'Transakcja Anulowana'],
  [2006, 'UserHash nie został odnaleziony'],
]);

export enum TerminalState {
  NO_CONNECTION = 'NO_CONNECTION',
  AMOUNT_INVALID = 'AMOUNT_INVALID',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  PAYMENT_STARTED = 'PAYMENT_STARTED',
  PAYMENT_STARTED_OK = 'OK', // todo remove after value in Android is changed
  repeat = 'repeat',
  cancel = 'cancel',
  exception = 'exception',
}

export enum PaymentState {
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
}
