import { PaymentState, TerminalState } from '../modules/payment/const';
import { PaymentType, OrderTicket, OrderProduct, PaymentProcessingStatus } from '../modules/payment/models';
import { AppDetails, DeviceDetails, getAppInfo } from './device';

export const getTerminal = async (): Promise<DeviceDetails> => {
  try {
    const appInfo = await getAppInfo();
    const deviceDetails = appInfo?.devices.find((x) => x.function === 'PAYMENT_TERMINAL');

    if (!deviceDetails) {
      throw new Error('Cannot find device with type PAYMENT_TERMINAL');
    }
    return deviceDetails;
  } catch (error) {
    console.error('Failed getting terminal: ' + JSON.stringify(error));
    return {} as DeviceDetails;
  }
};

export const getPrinter = async (): Promise<DeviceDetails | null> => {
  try {
    const appInfo = await getAppInfo();
    const deviceDetails = appInfo?.devices.find((x) => x.function === 'FISCAL_PRINTER');

    if (!deviceDetails) {
      console.log('Cannot get printer');
      return null;
    }
    // console.log('getPrinter - success');

    // deviceDetails.ip = '172.16.1.164';
    console.log(JSON.stringify(deviceDetails));
    return deviceDetails;
  } catch (error) {
    console.error('Failed getting terminal: ' + JSON.stringify(error));
    return {} as DeviceDetails;
  }
};

export const getAppDetails = async (): Promise<AppDetails> => {
  try {
    const deviceInfo = await getAppInfo();
    return deviceInfo;
  } catch (error) {
    console.error('Failed getting App Details: ' + JSON.stringify(error));
    return {} as AppDetails;
  }
};

export const startPayment = (amount: number, orderId: string) => {
  return new Promise<PaymentProcessingStatus>(async (resolve, reject) => {
    try {
      const terminal = await getTerminal();
      console.log('[TERMINAL] Found Terminal: ' + JSON.stringify(terminal));
      const terminalState = window.BtDevice.startPayment(
        terminal.type,
        terminal.ip,
        terminal.port,
        amount,
        orderId,
        JSON.stringify(terminal.additionalSettings)
      );

      console.log('[TERMINAL] Terminal - startPayment - Success', terminalState);

      resolve({ terminalState, ip: terminal.ip, port: terminal.port } as PaymentProcessingStatus);
    } catch (error) {
      console.error('[TERMINAL] Terminal - startPayment - Error', JSON.stringify(error));
      reject('ERROR_PAYMENT');
    }
  });
};

export const pairTerminal = (): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const terminal = await getTerminal();
      const response = window.BtDevice.pairTerminal(terminal.ip, terminal.port);

      resolve(response);
    } catch (error) {
      console.error('[TERMINAL] Terminal - pairTerminal - Error: Device Error', JSON.stringify(error));
      reject('ERROR_PAIRING');
    }
  });
};

export const printInvoice = async (
  orderId: string,
  tickets: OrderTicket[],
  products: OrderProduct[],
  nip: string | undefined,
  paymentType: PaymentType | null
): Promise<string> => {
  try {
    const nipValue = nip === undefined ? null : nip;
    console.log('Start printing ' + orderId + ', nip: ' + JSON.stringify(nipValue) + ' ' + JSON.stringify(tickets));
    const printer = await getPrinter();
    if (printer) {
      // TODO: merge to one method after Android update
      if (products.length > 0) {
        return window.BtDevice.printInvoice(
          printer.type,
          printer.ip,
          printer.port,
          orderId,
          JSON.stringify(tickets),
          JSON.stringify(products),
          nipValue,
          paymentType
        ) as string;
      } else {
        return window.BtDevice.printInvoice(
          printer.type,
          printer.ip,
          printer.port,
          orderId,
          JSON.stringify(tickets),
          nipValue,
          paymentType
        ) as string;
      }
    } else {
      console.log('Cannot get printer');
      return '';
    }
  } catch (error) {
    console.error('Failed printing invoice ', JSON.stringify(error));
    return '';
  }
};

export const IsPrinterConnected = async (): Promise<boolean> => {
  if (window.BtDevice) {
    const printer = await getPrinter();
    if (printer) {
      const result = window.BtDevice.isFiscalPrinterConnected(printer.type, printer.ip, printer.port) as boolean;
      console.log('[isFiscalPrinterConnected] Result: ' + result);
      return result;
    }
    console.error('[IsPrinterConnected] Cannot get printer data');
    return false;
  } else {
    console.log('[IsPrinterConnected] Skipping validation as window.BtDevice is not found');
    return true;
  }
};
