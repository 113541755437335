import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RedButton, VioletButton } from '../../components/styled-components';
import { TicketDetails } from '../../modules/tickets/models';
import { getFormattedDate } from './ValidTicket';

interface ExtendingTicketConfirmationProps {
  handleClose: () => void;
  handleConfirm: () => void;
  ticket: TicketDetails | null;
  open: boolean;
}
const ExtendingTicketConfirmation = ({
  handleClose,
  open,
  handleConfirm,
  ticket,
}: ExtendingTicketConfirmationProps) => {
  const { t } = useTranslation(['common']);
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ textAlign: 'center' }}>
        <>{t('common:ticketStatus.extendingTicket')}</>
      </DialogTitle>
      <DialogContent>
        <>{t('common:ticketStatus.extendConfirmation')}</>
      </DialogContent>
      <DialogActions sx={{ py: 3, justifyContent: 'center' }}>
        <RedButton onClick={handleClose}>
          <>{t('common:buttons.cancel')}</>
        </RedButton>
        <VioletButton onClick={handleConfirm}>
          <>{t('common:buttons.confirm')}</>
        </VioletButton>
      </DialogActions>
    </Dialog>
  );
};

export default ExtendingTicketConfirmation;
