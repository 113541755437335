import { ClientSetup } from '../../setup/ClientSetup';
import { Layout } from '../../setup/Layout';
import {
  AppDetails,
  AppFeature,
  AppPaymentType,
  PaymentProvider,
  PrimaryMode,
  ScannerIconSize,
  ScannerLocation,
  ScannerType,
  ScannerVerticalLocation,
} from '../../utils/device';
import { LayoutStateModel } from './models';

export const getClientSetup = (state: LayoutStateModel): ClientSetup => {
  return state.clientSetup;
};

export const getDeviceName = (state: LayoutStateModel): string => {
  return state.deviceName;
};

export const getClientLayout = (state: LayoutStateModel): Layout => {
  return state.layout;
};

export const getIsLoadingDone = (state: LayoutStateModel): boolean => {
  return state.isLoadingDone;
};

export const getEnabledFeatures = (state: LayoutStateModel): AppFeature[] => {
  return state.enabledFeatures;
};

export const getAcceptedPaymentTypes = (state: LayoutStateModel): AppPaymentType[] => {
  return state.acceptedPaymentTypes;
};

export const getEmbeddedQrScanner = (state: LayoutStateModel): boolean => {
  return state.embeddedQrScanner;
};

export const getEmbeddedLprScanner = (state: LayoutStateModel): boolean => {
  return state.embeddedLprScanner;
};

export const getEmbeddedQrScannerType = (state: LayoutStateModel): ScannerType => {
  return state.embeddedQrScannerType;
};

export const getDisableAppAnimations = (state: LayoutStateModel): boolean => {
  return state.disableAppAnimations;
};

export const getPrimaryMode = (state: LayoutStateModel): PrimaryMode => {
  return state.primaryMode;
};

export const getEmbeddedQrScannerLocation = (state: LayoutStateModel): ScannerLocation => {
  return state.embeddedQrScannerLocation;
};

export const getEmbeddedQrScannerVerticalLocation = (state: LayoutStateModel): ScannerVerticalLocation => {
  return state.embeddedQrScannerVerticalLocation;
};

export const getEmbeddedQrScannerIconSize = (state: LayoutStateModel): ScannerIconSize => {
  return state.embeddedQrScannerIconSize;
};

export const getPaymentProvider = (state: LayoutStateModel): PaymentProvider => {
  return state.paymentProvider;
};

export const getSelfCheckout = (state: LayoutStateModel): boolean => {
  return state.selfCheckout;
};

export const getAppDetails = (state: LayoutStateModel): AppDetails => {
  return state.appDetails;
};
