const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
const FETCH_PRODUCT = 'FETCH_PRODUCT';

const actionTypes = {
  FETCH_CATEGORIES,
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
};

export default actionTypes;
