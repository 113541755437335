import React from 'react';
import LoginForm from '../../components/LoginForm/LoginForm';

const Login = () => {
  return (
    <>
      <LoginForm></LoginForm>
    </>
  );
};

export default Login;
