import { useTheme, useMediaQuery, Stack } from '@mui/material';
import PaymentStepper from '../../components/PaymentStepper';
import { ReturnButton } from '../../components/styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getReturnUrl } from '../../utils/navigationHelper';
import useDisplayToolbarReturnButton from '../../layout/layoutHooks';
import { useTranslation } from 'react-i18next';
import PaymentTypeBox from '../../components/PaymentTypeBox';
import { useAppSelector } from '../../utils/hooks';
import { getPaymentProvider } from '../../modules/layout/selectors';
import React, { ReactNode } from 'react';

interface WizardStepsProps {
  children: ReactNode;
  activeStep: number;
  disableBackButton?: boolean;
}

const WizardSteps = ({ children, activeStep, disableBackButton }: WizardStepsProps) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isDownMDScreen = useMediaQuery(theme.breakpoints.down('md'));
  const displayToolbarReturnButton = useDisplayToolbarReturnButton();
  const paymentProvider = useAppSelector((x) => getPaymentProvider(x.layout));

  return (
    <>
      <Stack sx={{ pt: 2 }} spacing={2} direction={{ xs: 'column', md: 'row' }} justifyContent={'center'}>
        {!displayToolbarReturnButton && activeStep === 0 ? (
          <ReturnButton onClick={() => navigate(getReturnUrl(searchParams, '/summary'))} disabled={disableBackButton}>
            <>{t('common:buttons.back')}</>
          </ReturnButton>
        ) : null}
        <Stack sx={{ backgroundColor: 'white', py: 3, px: 6, width: isDownMDScreen ? '100%' : '80vw' }}>
          {!isDownMDScreen && <PaymentStepper activeStep={activeStep}></PaymentStepper>}
          {children}
          <PaymentTypeBox paymentType={paymentProvider} />
        </Stack>
      </Stack>
    </>
  );
};

export default WizardSteps;
