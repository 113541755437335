import { getEnabledFeatures } from '../modules/layout/selectors';
import { AppFeature } from '../utils/device';
import { useAppSelector } from '../utils/hooks';
import ModeSelector from './ModeSelector';
import Tickets from './Tickets';

const Home = () => {
  const enabledFeatures = useAppSelector((state) => getEnabledFeatures(state.layout));
  return <>{enabledFeatures.length === 1 && enabledFeatures[0] === AppFeature.TicketSale ? <Tickets></Tickets> : <ModeSelector></ModeSelector>}</>;
};

export default Home;
