import { Category, CategoryCode } from '../../modules/products/models';
import { Button, Stack, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid2 from '@mui/material/Unstable_Grid2';

interface CategoryFilterProps {
  categories: Category[];
  selectedCategoryId?: string;
  onClearSelectionClick: () => void;
  onCategoryClick: (categoryId: string) => void;
}

const getCategoryCode = (code: string): CategoryCode => {
  const category = CategoryCode[code];
  if (category) {
    return category;
  }
  return CategoryCode.undefined;
};

const CategoryFilter = ({
  categories,
  selectedCategoryId,
  onCategoryClick,
  onClearSelectionClick,
}: CategoryFilterProps) => {
  const { t } = useTranslation('components');
  const handleCategoryClick = (categoryId: string) => {
    if (categoryId === selectedCategoryId) {
      onClearSelectionClick();
    } else {
      onCategoryClick(categoryId);
    }
  };
  return (
    <Grid2 container spacing={2}>
      {categories.map((c) => (
        <Grid2 key={c.id}>
          <CategoryButton
            key={c.id}
            className={c.id === selectedCategoryId ? 'active' : ''}
            onClick={() => handleCategoryClick(c.id)}
          >
            {t(`categories.${getCategoryCode(c.code)}`)}
          </CategoryButton>
        </Grid2>
      ))}
      <Grid2>
        <CategoryButton
          onClick={onClearSelectionClick}
          sx={{ borderColor: '#16B1D4' }}
          className={selectedCategoryId === undefined ? 'active' : ''}
        >
          {t('categoryFilter.allProductsButton')}
        </CategoryButton>
      </Grid2>
    </Grid2>
  );
};

const CategoryButton = styled(Button)(({ theme }) => ({
  border: '1px #9464FC solid',
  justifyContent: 'center',
  borderRadius: '4px',
  textTransform: 'none',
  font: 'Poppins',
  fontWeight: 400,
  color: 'black',
  paddingY: theme.spacing(1),
  paddingX: theme.spacing(3),
  '&.active': {
    background: '#FFB039',
    color: 'white',
    border: '1px #FFB039 solid',
  },
}));

export default CategoryFilter;
